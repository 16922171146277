import { Step } from "./Step.js";
import { roundToTenth, tempCtoF } from "../utilities/ValueDisplayUtils.js";
import { TimerStep } from "./TimerStep.js";
const template = document.createElement('template');
template.innerHTML = `
<h2>Heat Water to Target Temperature</h2>
<p>At this point, you should already have your grounds prepared and in your press, awaiting water.</p>
<div class="hidden temperature">
  <p>Heat Water to Target Temperature, then pour water into grounds and click <span class="material-symbols-outlined intext-icon">arrow_forward</span></p>
  <div>
    <label class="units-metric">Target Temperature(C)</label>
    <input class="units-metric input_target_temperature_C" disabled type="number"/>
    <label class="units-std">Target Temperature(F)</label>
    <input class="units-std input_target_temperature_F" disabled type="number"/>
  </div>
  <button aria-label="continue" class="continue_btn"><span class="material-symbols-outlined">arrow_forward</span></button>
</div>
<div class="hidden cooldown">
  <p>Heat Water to boiling, then press button to begin cooldown timer. For most consistent results, use the same container to heat in each time.</p>
  <p>When Timer expires, pour water into grounds. Page will advance to next step</p>
  <div class="CountdownTimerContainer"><div id="cd" class="inner_timer"></div></div>
  <button aria-label="start" class="start_btn"><span class="material-symbols-outlined">start</span></button>
</div>
`;
export function factory_HeatStep(heatStepRootElement, brewConfig, useMetric, audioAlert) {
  heatStepRootElement.innerHTML = "";
  heatStepRootElement.appendChild(template.content.cloneNode(true));
  const temperatureHeatStep = new TemperatureHeatStep(heatStepRootElement, brewConfig.steep_temp_C, useMetric);
  const cooldownHeatStep = new CooldownHeatStep(heatStepRootElement, brewConfig.steep_cooldown_duration_s * 1000, audioAlert);
  temperatureHeatStep.hide();
  cooldownHeatStep.hide();

  if (brewConfig.steep_temp_control_type === "temperature")
    return temperatureHeatStep;

  else
    return cooldownHeatStep;
}
class TemperatureHeatStep extends Step {
  #temperatureEle;

  constructor(rootElement, targetTemp) {
    super(rootElement);
    const targetTempDisplayC = rootElement.querySelector(`.input_target_temperature_C`);
    const targetTempDisplayF = rootElement.querySelector(`.input_target_temperature_F`);
    targetTempDisplayC.value = targetTemp;
    targetTempDisplayF.value = roundToTenth(tempCtoF(targetTemp));
    this.#temperatureEle = rootElement.querySelector(`.temperature`);
    const continueBtn = this.#temperatureEle.querySelector(`.continue_btn`);
    continueBtn.onclick = () => { this.end(); };
  }
  show() {
    super.show();
    this.#temperatureEle.classList.remove("hidden");
  }
  hide() {
    super.hide();
    this.#temperatureEle.classList.add("hidden");
  }
}

const cooldownTemplate = document.createElement('template');


class CooldownHeatStep extends TimerStep {
  #cooldownEle;
  constructor(heatStepRootElement, duration_ms, audioAlert) {
    super(heatStepRootElement, duration_ms, null, audioAlert);
    this.#cooldownEle = heatStepRootElement.querySelector(`.cooldown`);
    const startBtn = this.#cooldownEle.querySelector(`.start_btn`);
    startBtn.onclick = () => { this.timer.start(); };
  }
  show() {
    super.show();
    this.#cooldownEle.classList.remove("hidden");
  }
  hide() {
    super.hide();
    this.#cooldownEle.classList.add("hidden");
  }
}
export class PressStep extends Step {
  constructor(pressStepEle) {
    super(pressStepEle);
    const continueBtn = pressStepEle.querySelector('.continue_btn');
    continueBtn.onclick = () => { this.end(); };
  }
}
