import { Step } from "./Step.js";
const template = document.createElement('template');
template.innerHTML = 
`
<h2>Results</h2>
<p>Enter brewing results below, then done button to log results and return to home screen</p>
<div class="results_input">
  <div class="field">
    <label>Strength</label>
    <select name="strength">
      <option value="weak">weak</option>
      <option value="below-average">below-average</option>
      <option value="average">average</option>
      <option value="above-average">above-average</option>
      <option value="strong">strong</option>
      <option value="excessive">excessive</option>
    </select>
  </div>
  <div class="field"><label>Extraction</label>
    <select name="extraction">
      <option value="severely-under">severely-under</option>
      <option value="under">under</option>
      <option value="good-under">good-under</option>
      <option value="good">good</option>
      <option value="good-over">good-over</option>
      <option value="over">over</option>
      <option value="severely-over">severely-over</option>
    </select>
  </div>
  <div class="field"><label>Notes</label>
    <textarea class="notes">

    </textarea>
  </div>
  <button aria-label="save record" class="save_btn"><span class="material-symbols-outlined">done</span></button>
</div>
`
export class ResultsStep extends Step {
  constructor(resultsStepEle, storageFn) {
    super(resultsStepEle);
    resultsStepEle.innerHTML = "";
    resultsStepEle.appendChild(template.content.cloneNode(true));
    this.storageFn = storageFn;
    this.strengthEle = resultsStepEle.querySelector('select[name="strength"]');
    this.extractionEle = resultsStepEle.querySelector('select[name="extraction"]');
    this.notesEle = resultsStepEle.querySelector('.notes');
    this.saveBtn = resultsStepEle.querySelector('.save_btn');
    this.saveBtn.onclick = () => {
      this.#save();
      this.end();
    };
    this.notesEle.value = "";
  }

  #save() {
    const results = {
      timestamp_epoch_ms: Date.now(),
      strength: this.strengthEle.value,
      extraction: this.extractionEle.value,
      notes: this.notesEle.value,
    };
    if (this.storageFn)
      this.storageFn(results);

    else
      console.debug("no results storage function defined");
  }
}
