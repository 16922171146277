const timestampTemplate = document.createElement('template');
timestampTemplate.innerHTML = 
`
<div class="field timestamp">
  <label>Timestamp</label>
  <span aria-label="Timestamp" tabindex="0"></span>
</div>
`
export class TimestampField extends HTMLElement {
  constructor() {
    super();
  }

  #redraw() {
    const timestamp = this.getAttribute("timestamp");
    const span = this.querySelector('span');
    if(!timestamp || !span)
      return
    const date = new Date(Number.parseInt(timestamp));    
    span.innerText = date.toLocaleString();
  }
  connectedCallback() {
    this.appendChild(timestampTemplate.content.cloneNode(true));
    this.#redraw();
  }

  static get observedAttributes() {
    return ["timestamp"];
  }

  attributeChangeCallback(name:string, oldValue:string, newValue:string) {
    if(name === "timestamp")
      this.#redraw();
  }
}

globalThis.customElements.define("timestamp-field", TimestampField);