import { TBrewResult } from "./LoadRecentBest";

const strengths = ["weak", "below-average", "average", "above-average", "strong", "excessive"];
type  TStrength = "weak" | "below-average" | "average" | "above-average" | "strong" | "excessive";
const extractions = ["severely-under", "under", "good-under", "good", "good-over", "over", "severely-over"];
type TExtraction = "severely-under" | "under" | "good-under" | "good" | "good-over" | "over" | "severely-over";
export function lessThanUsingMinSortedArray(v1:any, v2:any, arr:any[]) {
  const index1 = arr.findIndex(value => v1 === value);
  const index2 = arr.findIndex(value => v2 === value);
  return index1 < index2;
}

export function compareValuesUsingMinSortedArray(v1:any, v2:any, arr:any[]) {
  const firstLess = lessThanUsingMinSortedArray(v1, v2, arr);
  const secondLess = lessThanUsingMinSortedArray(v2, v1, arr);
  if(firstLess)
    return -1;
  if(secondLess)
    return 1;
  return 0;
}

export function compareStrengths(strength1:TStrength, strength2:TStrength) {
  return compareValuesUsingMinSortedArray(strength1, strength2, strengths);
}

export function compareExtractions(extraction1:TExtraction, extraction2:TExtraction) {
  return compareValuesUsingMinSortedArray(extraction1, extraction2, extractions);
}

export function compareBrewResults(result1:TBrewResult, result2:TBrewResult) {
  const strength = compareStrengths(result1.strength, result2.strength);
  const extraction = compareExtractions(result1.extraction, result2.extraction);  
  if(extraction !== 0)
    return extraction;
  if(strength !== 0)
    return strength;
  const timestampCmp = result1.timestamp_epoch_ms - result2.timestamp_epoch_ms;
  if(timestampCmp === 0)
    return result1.notes.localeCompare(result2.notes);
  if(timestampCmp < 0)
    return -1
  return 1;
}

export function getStrengthRating(value:string):"good"|"bad"|"" {
  const strengthGood = ["average", "above-average", "strong", "excessive"].includes(value);
  const strengthBad = ["weak", "below-average"].includes(value);
  const strengthValue = (strengthGood && "good") || (strengthBad && "bad") || "";
  return strengthValue;
}

export function getExtractionRating(value:string):"good"|"bad" {
  const extractionGood = ["good-under", "good", "good-over"].includes(value);
  const extractionValue = (extractionGood && "good") || "bad";
  return extractionValue;
}

export function buildBrewRatings(result:TBrewResult):{strength:"good"|"bad"|"", extraction:"good"|"bad"} {
  const strengthValue = getStrengthRating(result.strength);
  const extractionValue = getExtractionRating(result.extraction);
  return {
    strength: strengthValue,
    extraction: extractionValue
  };
}
