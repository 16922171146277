import { CBrewConfig } from "../data/BrewConfig.js";
import { LiveValue } from "../utilities/LiveValue.js";

function init_brew_config(rootEle:HTMLElement, brewConfig:CBrewConfig, updaterFn:TBrewUpdaterFn, useOldFields = false) {
  const oldSelector = (useOldFields) ? '.old_value' : '';
  const fieldPairs = [
    ["input_grounds_quantity",brewConfig.grounds_quantity, (value:number) => brewConfig.withGroundsQuantity(value), "number"],
    ["input_steep_water_quantity",brewConfig.steep_water_quantity,(value:number) => brewConfig.withSteepWaterQuantity(value), "number"],
    ["input_temperature_value",brewConfig.steep_temp,(value:number) => brewConfig.withSteepTemp(value), "number"],
    ["input_temperature_cooldown",brewConfig.steep_cooldown_duration, (value:number) => brewConfig.withSteepDuration(value), "number"],
    ["input_bloom_duration_s", brewConfig.bloom_duration,(value:number) => brewConfig.withBloomDuration(value), "number"],
    ["input_first_stir_duration_s",brewConfig.stir_duration,(value:number) => brewConfig.withStirDuration(value), "number"],
    ["input_steep_duration_s",brewConfig.steep_duration,(value:number) => brewConfig.withSteepDuration(value), "number"],
    ["input_additional_water_quantity",brewConfig.additional_water_quantity, (value:number) => brewConfig.withAdditionalWaterQuantity(value), "number"],
    ["select_temperature_control_type",brewConfig.steep_temp_control_type, (value:"post_boil_cooldown"|"temperature") => brewConfig.withSteepTempControlType(value), "string"],
  ]
  
  fieldPairs.forEach((pair) => {
    const selector = `.${pair[0]}${oldSelector}`;
    const element = rootEle.querySelector(selector) as HTMLInputElement;
    if(!element)
      throw new Error("Required selector not found: "+selector);
    const rawValue = pair[1];
    element.value = pair[1].toString();
    if(useOldFields)
      return;

    element.onchange = (event) => {
      //const rawValue = Number.parseFloat((event?.target as HTMLInputElement)?.value);
      const rawValue = (pair[3] === "number") ? Number.parseFloat((event?.target as HTMLInputElement)?.value)
        : (event?.target as HTMLInputElement).value;
      const fn = pair[2] as any
      const newConfig = fn(rawValue)
      updaterFn(newConfig);
    };
  });
}

const template = document.createElement("template");
template.innerHTML = 
`
<header class="content_header">
  <h2>Brew Configuration</h2>
  <button><span class="material-symbols-outlined">content_copy</span></button>
</header>
<div class="field target_density_ui"></div>
<div class="field">
  <label>Grounds <span class="units_label quantity">(mL)</span></label>
  <input class="input_grounds_quantity" type="number" step="0.1" value="30" />
  <input readonly class="old_value input_grounds_quantity" type="number" value="30" />
</div>
<div class="field">
  <label>Steep Water <span class="units_label quantity">(mL)</span></label>
  <input type="number" step="0.1" class="input_steep_water_quantity" />
  <input readonly type="number" step="0.5" class="old_value input_steep_water_quantity" />
</div>
<div class="field">
  <label>Temp Control</label>
  <select name="temperature_control_type" class="select_temperature_control_type">
    <option value="temperature">Temp</option>
    <option selected value="post_boil_cooldown">Cooldown</option>
  </select>
  <input readonly name="temperature_control_type" class="old_value select_temperature_control_type" />
</div>
<div class="field">
  <label>Temp <span class="units_label temperature">(C)</span></label>
  <input class="input_temperature_value" type="number" min="0", step="1"/>
  <input readonly class="old_value input_temperature_value" type="number" min="0", step="1"/>
</div>
<div class="field">
  <label>Temp Cooldown (s)</label>
  <input class="input_temperature_cooldown" type="number" min="0" step="1" />
  <input readonly class="old_value input_temperature_cooldown" type="number" min="0" step="1" />
</div>
<div class="field">
  <label>Bloom Time(s)</label>
  <input class="input_bloom_duration_s" type="number" />
  <input readonly class="old_value input_bloom_duration_s" type="number" />
</div>
<div class="field">
  <label>Stir Time(s)</label>
  <input class="input_first_stir_duration_s" type="number" />
  <input readonly class="old_value input_first_stir_duration_s" type="number" />
</div>
<div class="field">
  <label>Steep (s)</label>
  <input class="input_steep_duration_s" type="number" />
  <input readonly class="old_value input_steep_duration_s" type="number" />
</div>
<div class="field">
  <label>Extra Water <span class="units_label quantity">(mL)</span></label>
  <input class="input_additional_water_quantity" type="number" ></input>
  <input readonly class="old_value input_additional_water_quantity" type="number" ></input>
</div>
`;
type TBrewUpdaterFn = (v:CBrewConfig) => void;
export class BrewConfigViewElement extends HTMLElement{  
  #brewUpdateFn:TBrewUpdaterFn
  #oldBrewUpdateFn:TBrewUpdaterFn
  #brewConfig:CBrewConfig|undefined;
  #oldBrewConfig:CBrewConfig|undefined;
  #connectedOnce:boolean = false;
  constructor() {
    super();
    this.#brewUpdateFn = () => {};
    this.#oldBrewUpdateFn = () =>{};
  }

  connectedCallback() {
    if(!this.#connectedOnce) {
      this.#connectedOnce = true;
      this.appendChild(template.content.cloneNode(true));
    }
    this.redraw();
  }

  #configureQuantityLabels() {
    if(!this.#brewConfig)
      return;
    const unitLabels = this.querySelectorAll('.units_label.quantity');
    unitLabels.forEach(element => {
      let label = "[unk]";
      switch(this.#brewConfig?.quantityUnits) {
        case "fluid-ounces": label = "(floz)";break;
        case "grams":label = "(g)";break;
        case "milliliters":label = "(mL)"; break;
      }
      element.innerHTML = label;
    });
  }

  #configureTemperatureLabels() {
    if(!this.#brewConfig)
      return;
    const unitLabels = this.querySelectorAll('.units_label.temperature');
    unitLabels.forEach(element => {
      let label = '(C)';
      switch(this.#brewConfig?.unitSystem) {
        case "metric": label = "C";break;
        case "std": label = "(F)";break;
      }
      element.innerHTML = label;
    });
  }

  #configureUnitLabels() {
    this.#configureQuantityLabels();
    this.#configureTemperatureLabels();
  }

  set(brewConfig:CBrewConfig, brewUpdateFn:TBrewUpdaterFn, oldBrewConfig:CBrewConfig, oldBrewUpdateFn:TBrewUpdaterFn) {
    this.#brewConfig = brewConfig;
    this.#oldBrewConfig = oldBrewConfig;
    this.#brewUpdateFn = brewUpdateFn;
    this.#oldBrewUpdateFn = oldBrewUpdateFn;
    this.#configureUnitLabels();
    this.redraw();
  }

  #redrawOldConfig() {
    if(!this.#oldBrewConfig)
      return;
    init_brew_config(this, this.#oldBrewConfig, this.#oldBrewUpdateFn, true);
  }
  #redrawConfig() {
    if(!this.#brewConfig)
      return;
    init_brew_config(this, this.#brewConfig, this.#brewUpdateFn, false);
  }
  redraw() {
    
    this.#redrawConfig();
    this.#redrawOldConfig();
  }
}

customElements.define('brew-config-view', BrewConfigViewElement);