export class Step extends EventTarget {
  rootEle:HTMLElement;
  onended:undefined|(()=>void);

  constructor(rootElement:HTMLElement) {
    super();
    this.rootEle = rootElement;
  }

  show() {
    this.rootEle.classList.remove("hidden");
  }
  start() {
    this.show();
  }

  end() {
    this.hide();
    const event = new CustomEvent("ended");
    this.dispatchEvent(event);
    if (this.onended)
      this.onended();
  }
  stop() {
    this.hide();
  }
  hide() {
    this.rootEle.classList.add("hidden");
  }
}
