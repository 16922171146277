const soundOnTemplate = document.createElement("template");
soundOnTemplate.innerHTML = 
`
<button>
  <span class="sound_on material-symbols-outlined">volume_up</span>
</button>
`;

const soundOffTemplate = document.createElement("template");
soundOffTemplate.innerHTML = 
`
<button>
  <span class="sound_off material-symbols-outlined">no_sound</span>
</button>
`;
export class SoundStatus extends HTMLElement {
  #connectedOnce = false;
  #soundEnabled = true;
  constructor() {
    super();
  }

  get isSoundEnabled() { return this.#soundEnabled;}

  connectedCallback() {
    if(this.#connectedOnce)
      return;
    this.#connectedOnce = true;
    this.#layout(true);
  }
  
  disableSound() {
    if(!this.#soundEnabled)
      return;
    this.#layout(false);
    const event = new CustomEvent("change", {detail:{soundEnabled:false}});
    this.dispatchEvent(event);
  }

  #layout(enabled:boolean) {
    this.#soundEnabled= enabled;
    this.innerHTML = "";
    this.appendChild(
      (enabled)
      ? soundOnTemplate.content.cloneNode(true)
      : soundOffTemplate.content.cloneNode(true)
    );
    const button = this.querySelector('button') as HTMLButtonElement;
    button.onclick = () => {
      if(enabled)
        this.disableSound();
      else
        this.enableSound();
    };
  }

  enableSound() {
    if(this.#soundEnabled)
      return;
    this.#layout(true);
    const event = new CustomEvent("change", {detail:{soundEnabled:true}});
    this.dispatchEvent(event);
  }

  setSoundStatus(enabled:boolean) {
    if(enabled)
      this.enableSound()
    else
      this.disableSound();
  }
}

globalThis.customElements.define("sound-status", SoundStatus);