import { Step } from "./Step.js";
import { CountdownTimerElement } from "../ui_components/CountdownTimer.js";

export class TimerStep extends Step {
  constructor(stepRootElement, duration_ms, delay_ms, audioAlert) {
    super(stepRootElement);
    this.duration_ms = duration_ms;
    this.delay_ms = delay_ms;

    const timerElement = stepRootElement.querySelector('.CountdownTimerContainer');
    this.timer = new CountdownTimerElement(timerElement, audioAlert);
    this.timer.setTo(duration_ms);
    this.timer.onend = () => { this.end(); };
    

  }

  #clearTimers() {
    if (this.delayTimout)
      clearTimeout(this.delayTimout);
    this.timer.stop();
  }

  stop() {
    super.stop();
    this.#clearTimers();
  }

  start() {
    this.#clearTimers();
    super.start();
    if (this.delay_ms === null)
      return;
    this.delayTimout = setTimeout(() => { this.timer.start(); }, this.delay_ms);
  }
}
