
export interface StorageObject {
  get key(): string;
  get default(): any;
  get decoder(): (str: string) => any;
  get encoder(): (value: any) => string;
}

export class ObjectStorage extends EventTarget {
  #storage: Storage;
  #scopeKey: string;
  #current = new Map<string, any>;
  constructor(storage: Storage, scopeKey: string = "") {
    super();
    this.#storage = storage;
    this.#scopeKey = scopeKey;
  }
  #getTotalKey(key: string) {
    return this.#scopeKey + "_" + key;
  }

  get(option: StorageObject) {
    const current = this.#current.get(option.key);
    if (typeof current !== 'undefined')
      return current;
    const totalKey = this.#getTotalKey(option.key);
    const stored = this.#storage.getItem(totalKey);
    if (stored === null)
      return option.default;
    const decoded = option.decoder(stored);
    this.#current.set(option.key, decoded);
    return decoded;
  }

  set(value: any, option: StorageObject) {
    const encoded = option.encoder(value);
    const totalKey = this.#getTotalKey(option.key);
    this.#current.set(option.key, value);
    this.#storage.setItem(totalKey, encoded);
    const event = new CustomEvent("update", {
      detail: {
        storageObject: option,
        value: value,
      }
    });
    this.dispatchEvent(event);
  }
}
