import { TBrewConfig } from "../data/BrewConfig";
import { densityFromRoast_g_mL } from "../data/Density";
import { LiveValue } from "../utilities/LiveValue";
import { roundToTenth } from "../utilities/ValueDisplayUtils";

const template = document.createElement('template');
template.innerHTML = `
<h3>Water/Coffee Ratios(by weight)</h3>
<div class="field total_ratio">
<label>Total Ratio</label>
<input readonly value="0" class="new_value " ></input>
<input readonly value="0" class="old_value " ></input>
</div>
<div class="field steep_ratio">
<label>Steep Ratio</label>
<input readonly value="0" class="new_value " ></input>
<input readonly value="0" class="old_value " ></input>
</div>
`;

export class TargetDensityUI {
  #rootEle;
  constructor(rootEle:HTMLElement, liveBrewConfig:LiveValue, liveSourceConfig:LiveValue, liveOldBrewConfig:LiveValue, liveOldSourceConfig:LiveValue) {
    this.#rootEle = rootEle;
    this.#rootEle.innerHTML = "";
    this.#rootEle.appendChild(template.content.cloneNode(true));
    this.#rootEle.classList.add("target-density-ui-root");

    const totalValueEle = rootEle.querySelector('.total_ratio .new_value') as HTMLInputElement;
    const oldTotalValueEle = rootEle.querySelector('.total_ratio .old_value')  as HTMLInputElement;
    const steepEle = rootEle.querySelector('.steep_ratio .new_value')  as HTMLInputElement;
    const steepOldEle = rootEle.querySelector('.steep_ratio .old_value')  as HTMLInputElement;
    const updateText = () => {
      totalValueEle.value = getDisplayRatio(liveBrewConfig?.value, liveSourceConfig?.value);      
      oldTotalValueEle.value = getDisplayRatio(liveOldBrewConfig?.value, liveOldSourceConfig?.value);
      steepEle.value = getDisplayRatio(liveBrewConfig?.value, liveSourceConfig?.value, false);
      steepOldEle.value = getDisplayRatio(liveOldBrewConfig?.value, liveOldSourceConfig?.value, false);
    };
    liveBrewConfig?.addEventListener("update", () => {
      updateText();
    });
    liveSourceConfig?.addEventListener("update", () => {
      updateText();
    });
    liveOldBrewConfig?.addEventListener("update", () => {
      updateText();
    });
    liveOldSourceConfig?.addEventListener("update",  () => {
      updateText();
    });

    updateText();
  } 
}

function numberParseCheck(value:any) {
  if(typeof value === "string")
    return Number.parseFloat(value);
  if(typeof value !== "number")
    throw new Error("Invalid value passed. Expected Number or Number Parseable string");
  return value;
}
function getDisplayRatio(brewConfig:TBrewConfig, sourceConfig:any, total=true) {
  if(!brewConfig || !sourceConfig)
    return "";
  const density = sourceConfig.density_g_mL || densityFromRoast_g_mL(sourceConfig.roast);
  //const estimated = !sourceConfig.density_g_mL && !brewConfig.grounds_quantity_g;
  const estimated = true;
  const water_g = (total) ?
      numberParseCheck(brewConfig.steep_water_quantity_mL)+numberParseCheck(brewConfig.additional_water_quantity_mL)
    : brewConfig.steep_water_quantity_mL;

  //const grounds_g = brewConfig.grounds_quantity_g || brewConfig.grounds_quantity_mL * density;
  const grounds_g = brewConfig.grounds_quantity_mL * density;
  const ratio = roundToTenth(water_g/grounds_g);
  // console.log("sourceConfig", sourceConfig);
  // console.log(`density:${density}, water_g:${water_g}, grounds_g:${grounds_g}, ratio:${ratio}`);
  return (estimated) ? 
      `${ratio} (est)`
    : `${ratio}`;
}