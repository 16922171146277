const template = document.createElement("template");
template.innerHTML = 
`<span class="icon">
  <slot name="icon"></slot>
  <div class="popup">
    <slot></slot>
  </div>
</span>
<style>
   .popup { 
      display:none;
   }
   .icon:hover .popup, .icon:focus-visible {
      position:absolute;
      text-align:left;
      width:max-content;
      max-width:95vw;
      overflow:hidden;
      display:inline;
      border-radius:0.5rem;
      padding:0.5rem;
      background-color:tan;
      left:0;
      transform:translate(0%, -100%);
   }
  
</style>
`;


export class InformationPopup extends HTMLElement {
  #firstLayoutFinished:boolean = false
  constructor() {
    super();
    this.classList.add("information-popup");
  }

  connectedCallback() {
    if(this.#firstLayoutFinished)
      return;
    this.#firstLayoutFinished = true;
    
    const shadowRoot = this.attachShadow({mode:"open"});
    shadowRoot.appendChild(template.content.cloneNode(true));
  }
}

customElements.define("information-popup", InformationPopup);
