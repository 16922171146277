import { TVolUnits, TWeightUnits } from "./BrewConfig";
import { StorageObject, ObjectStorage } from "./ObjectStorage";


export type TUnitsConfig="std"|"metric";
export type TAutoloadBestBrewConfig = boolean;
export type TAutoloadLastSource = boolean;

export class BrewAppConfiguration extends EventTarget {
  #units:StorageObject;
  #autoloadSource:StorageObject;
  #autoloadBestBrew:StorageObject;
  #objectStorage;
  #soundEnabled:StorageObject;
  #quantityType:StorageObject;
  constructor(storage:ObjectStorage) {
    super();
    this.#objectStorage = storage;

    this.#quantityType = {
      key:'quantity_type',
      default:'mass',
      encoder:(value:TVolUnits|TWeightUnits)=>{return JSON.stringify({value:value})},
      decoder:(value:string):TVolUnits|TWeightUnits => {return JSON.parse(value).value},
    };

    this.#units = {
      key:"units",
      default:"std",
      encoder:(value:TUnitsConfig)=>{return JSON.stringify({value:value})},
      decoder:(value:string):TUnitsConfig => {return JSON.parse(value).value},
    };

    this.#autoloadBestBrew = {
      key:"autoload_best_brew",
      default:true,
      encoder:(value:boolean) => {return JSON.stringify({value:value});},
      decoder:(value:string):boolean => {return JSON.parse(value).value === "true"}
    }

    this.#autoloadSource = {
      key:"autoload_last_source",
      default:true,
      encoder:(value:boolean) => {return JSON.stringify({value:value});},
      decoder:(value:string):boolean => {return JSON.parse(value).value === "true"}
    }

    this.#soundEnabled = {
      key:"sound_enabled",
      default:true,
      encoder:(value:boolean) => {return JSON.stringify({value:value});},
      decoder:(value:string):boolean => {return JSON.parse(value).value === true}
    }
  }
  
  get quantityType() {
    return this.#objectStorage.get(this.#quantityType);
  }
  set quantityType(value:TVolUnits|TWeightUnits) {
    this.#objectStorage.set(value, this.#quantityType);
    const event = new CustomEvent("quantity_type", {detail:value});
    this.dispatchEvent(event);
  }

  get units() {
    return this.#objectStorage.get(this.#units);
  }
  set units(value:"std"|"metric") {
    this.#objectStorage.set(value, this.#units);
    const event = new CustomEvent("units", {detail:value});
    this.dispatchEvent(event);
  }

  get autoloadBestBrew() {
    return this.#objectStorage.get(this.#autoloadBestBrew);
  }

  set autoloadBestBrew(value:boolean) {
    this.#objectStorage.set(value, this.#autoloadBestBrew);
    const event = new CustomEvent("autoload_best_brew", {detail:value});
    this.dispatchEvent(event);
  }

  get autoloadLastSource() {
    return this.#objectStorage.get(this.#autoloadSource);
  }
  set autoloadLastSource(value:boolean) {
    this.#objectStorage.set(value, this.#autoloadSource);
    const event = new CustomEvent("autoload_last_source", {detail:value});
    this.dispatchEvent(event);
  }
  
  get soundEnabled() {
    return this.#objectStorage.get(this.#soundEnabled);
  }
  set soundEnabled(value:boolean) {
    this.#objectStorage.set(value, this.#soundEnabled);
    const event = new CustomEvent("sound_enabled", {detail:value});
    this.dispatchEvent(event);
  }
}

export function buildLocalBrewAppConfigStorage(scopeKey:string) {
  if(!globalThis.localStorage)
    return undefined;
  const objectStorage = new ObjectStorage(globalThis.localStorage, scopeKey);
  const configStorage = new BrewAppConfiguration(objectStorage);
  return configStorage;
}

export function buildSessionBrewAppConfigStorage(scopeKey:string) {
  if(!globalThis.sessionStorage)
    return undefined;
  const objectStorage = new ObjectStorage(globalThis.sessionStorage, scopeKey);
  const configStorage = new BrewAppConfiguration(objectStorage);
  return configStorage;
}

export function buildMemoryBrewAppConfigStorage(scopeKey:string) {
  const map = new Map<string, any>();
  const storage = {

    setItem:(key:string, value:any)=>{
      map.set(key, value);
    },
    getItem:(key:string) => {
      return map.get(key);
    }
  } as Storage;
  const objectStorage = new ObjectStorage(storage, scopeKey);
  const configStorage = new BrewAppConfiguration(objectStorage);
  return configStorage;
}