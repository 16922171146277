import { buildBrewRatings } from "../data/buildBrewRatings.js";

export class HistoryRecordView extends EventTarget {
  #rootEle;
  #resultRecord;
  constructor(rootElement, resultRecord = null){
    super();
    this.#rootEle = rootElement;
    this.#resultRecord
    const clearBtn = rootElement.querySelector('.clear_btn');
    clearBtn.onclick = (origEvent) => {
      origEvent.stopPropagation();
      const event = new CustomEvent("clear", {detail:this.#resultRecord});
      this.dispatchEvent(event);
    }
    HistoryRecordView.#fillLayout(rootElement, resultRecord);
  }

  updateRecord(resultRecord) {
    this.#resultRecord = resultRecord;
    HistoryRecordView.#fillLayout(this.#rootEle, resultRecord);
  }

  static #setGoodBadClasses(element, rating) {
    const toRemove = [];
    if(rating !== "good")
      toRemove.push("good");
    if(rating !== "bad")
      toRemove.push("bad");
    element.classList.remove(toRemove);
    if(!rating)
      return;
    element.classList.add(rating);
  }

  static #fillLayout(rootEle, resultRecord) {
    if(!resultRecord)
      rootEle.classList.add("hidden");
    else
      rootEle.classList.remove("hidden");
    
    const ratingClasses = (resultRecord) ? buildBrewRatings(resultRecord) : {strength:"", extraction:""};
    const strengthView = rootEle.querySelector(`.history_field .strength`);
    const extractionView = rootEle.querySelector(`.history_field .extraction`);
    const notesView = rootEle.querySelector('.history_field .notes');
    const timestampView = rootEle.querySelector('.timestamp');
    this.#setGoodBadClasses(strengthView, ratingClasses.strength);
    this.#setGoodBadClasses(extractionView, ratingClasses.extraction);

    strengthView.value = resultRecord?.strength || "";
    extractionView.value = resultRecord?.extraction || "";
    notesView.value = resultRecord?.notes || "";
    const epoch = resultRecord?.timestamp_epoch_ms;    
    timestampView.innerText = (epoch) ? new Date(epoch).toLocaleString() : "";
  }
}