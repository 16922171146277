import { MutuallyExclusiveClassGroup } from "../utilities/MutuallyExclusiveClassGroup.js";
import './EditableCompactBrewRecord';
import { EditableCompactBrewRecord } from "./EditableCompactBrewRecord";

const deleteConfirmationTemplate = document.createElement('template');
deleteConfirmationTemplate.innerHTML = 
`
<p>Delete Brew Record?</p>
<div>
  <button class="confirm">
    <span class="material-nofill material-symbols-outlined">delete</span>
  </button>
  <button class="close">
    <span class="material-nofill material-symbols-outlined">close</span>
  </button>
</div>
`;

function buildDeleteConfirmationDialog() {
  const dialog = document.createElement("dialog");  
  dialog.classList.add("DeleteConfirmationDialog");
  dialog.appendChild(deleteConfirmationTemplate.content.cloneNode(true));
  const confirmBtn = dialog.querySelector('.confirm');
  confirmBtn.onclick = (evt) => {
    evt.stopPropagation();
    const event = new CustomEvent("confirm");
    dialog.dispatchEvent(event);
    dialog.close();
  };
  const closeBtn = dialog.querySelector('.close');
  closeBtn.onclick = (event) =>{
    event.stopPropagation();
    dialog.close();
  }
  return dialog;
}

class BrewRecordFilterOptions extends EventTarget {
  #rootEle;
  #checkName;
  #checkGrind;
  #checkRoast;
  #state;
  constructor(filterRootEle) {
    super();
    this.#rootEle = filterRootEle;
    this.#checkName = filterRootEle.querySelector('input.filter_name');
    this.#checkGrind = filterRootEle.querySelector('input.filter_grind');
    this.#checkRoast = filterRootEle.querySelector('input.filter_roast');
    this.#updateState();
    filterRootEle.onchange = () => {
      this.#updateState();
      this.#notifyUpdate();
    }
  }
  get checked() { return this.#state;}

  #notifyUpdate() {
    const event = new CustomEvent("update", {detail:this.#state});
    this.dispatchEvent(event)
  }

  #updateState() {
    this.#state = {
      name:this.#checkName.checked,
      grind:this.#checkGrind.checked,
      roast:this.#checkRoast.checked
    }
  }
}

function filterRecords(source, records, checked) {
  if(!records || !source)
    return records;
  const filtered = records.filter(record => {
    const keepName = (!checked.name || record.source.name.toLowerCase() === source.name.toLowerCase());
    const keepRoast = (!checked.roast || record.source.roast.toLowerCase() === source.roast.toLowerCase());
    const keepGrind = (!checked.grind || record.source.grind.toLowerCase() === source.grind.toLowerCase());
    return (keepName && keepRoast && keepGrind);    
  });
  return filtered;
}

export class HistoryDialog extends EventTarget  {
  #list;
  #rootEle;
  #deleteConfirmDialog;
  constructor(dialogElement, pastBrews, currentSource = null) {
    super();
    this.#rootEle = dialogElement;
    this.brews = pastBrews;
    this.currentSource = currentSource
    const filterRootEle = dialogElement.querySelector('header fieldset');
    this.filterView = new BrewRecordFilterOptions(filterRootEle);
    this.filterView.addEventListener("update", () => {
      this.#buildBrews();
    });
    this.#buildBrews();
    const closeBtn = dialogElement.querySelector('.close_btn');
    closeBtn.onclick = () => {dialogElement.close();}
  }

  #buildBrews() {
    const list = this.#rootEle.querySelector('.history_list');
    list.innerHTML = "";
    const filteredBrews = filterRecords(this.currentSource, this.brews, this.filterView.checked);
    if(!filteredBrews)
      return;
    filteredBrews.forEach(brew => {
      const rootEle = document.createElement('div');
      const recordView = new EditableCompactBrewRecord(rootEle, brew);
      recordView.addEventListener("delete", (event) => {
        this.showDeleteConfirmationDialog(brew);
      });
      list.append(rootEle);
      rootEle.onclick = (evt)=>{
        this.#brewSelected(evt.currentTarget, brew);
      };

      recordView.addEventListener("update", (event) => {
        this.#brewUpdated(event.detail.record);
      });
    });
    const childrenArray = Array.from(list.children);
    this.exclusiveGroup = new MutuallyExclusiveClassGroup(childrenArray, "selected");
  }
  
  #brewSelected(brewEle, brew) {
    const event = new CustomEvent("selected", {detail:brew});
    this.dispatchEvent(event);
    brewEle.classList.add("selected");
  }

  #brewUpdated(brew) {
    const event = new CustomEvent("updateBrew", {detail:brew});
    this.dispatchEvent(event);
  }

  showDeleteConfirmationDialog(brew) {
    const deleteConfirmationDialog = buildDeleteConfirmationDialog();
    this.#rootEle.appendChild(deleteConfirmationDialog);
    deleteConfirmationDialog.addEventListener("confirm",() => {
      const event = new CustomEvent("deleteBrew", {detail:brew});
      this.dispatchEvent(event);
    });
    deleteConfirmationDialog.addEventListener("close", () => {
      this.#rootEle.removeChild(deleteConfirmationDialog);
    });
    deleteConfirmationDialog.showModal();
  }
}