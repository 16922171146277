const editControlsTemplate = document.createElement('template');
editControlsTemplate.innerHTML = 
`
<button class="edit_btn">
  <span class="material-symbols-outlined material-nofill">edit</span>
</button>
<button class="delete_btn">
  <span class="material-symbols-outlined material-nofill">delete</span>
</button>
<div class="EditModePanel" aria-hidden="true">
  <button class="discard_btn"><span class="material-symbols-outlined material-nofill">cancel</span></button>
  <button class="save_btn"><span class="material-symbols-outlined material-nofill">save</span></button>
</div>
<style>
 .BrewRecordContextMenu button {
  background-color:transparent;
  border:none;
 }
 
 .BrewRecordContextMenu .EditModePanel {
  background-color:var(--header-color, tan);
 }

 .BrewRecordContextMenu .edit_btn {
   border-radius:0.5rem;
   position:absolute;
   right:0;
   bottom:0;
 }

 .BrewRecordContextMenu .delete_btn {
   border-radius:0.5rem;
   position:absolute;
   top:0;
   right:0;
 }

.BrewRecordContextMenu :is(.EditModePanel, .edit_btn, .delete_btn)  {
  animation-name:slideBtn;
  animation-duration: 500ms;
  animation-fill-mode: both;
}

.BrewRecordContextMenu .EditModePanel {
  border-radius:0.5rem;
  position:absolute;
  top:0;
  right:0;
  flex-direction:row;
  gap:0.5rem;
}

@keyframes slideBtn {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
 }
 

 @keyframes slideBtnB {
  0% {
    visibility:visible;
    transform: translateX(0%);
  }

  100% {
    visibility:hidden;
    transform: translateX(100%);
  }
 }

.BrewRecordContextMenu [aria-hidden="true"]:is(.EditModePanel, .edit_btn, .delete_btn) {
  visibility:hidden;
  animation-name:slideBtnB;
  animation-duration: 500ms;
  animation-fill-mode:both;
 }

</style>
`;

class BrewRecordContextMenu extends HTMLElement {
  #editModePanel:HTMLElement|null;
  #deleteBtn:HTMLButtonElement|null;
  #saveBtn:HTMLButtonElement|null;
  #discardBtn:HTMLButtonElement|null;
  #editBtn:HTMLButtonElement|null;
  constructor() {
    super();
    this.#deleteBtn = null;
    this.#editModePanel = null;
    this.#discardBtn = null;
    this.#saveBtn = null;
    this.#editBtn = null;
  }

  connectedCallback() {
    this.classList.add("BrewRecordContextMenu");
    this.appendChild(editControlsTemplate.content.cloneNode(true));    
    this.#editModePanel = this.querySelector('.EditModePanel') as HTMLElement;
    this.#deleteBtn = this.querySelector('.delete_btn') as HTMLButtonElement;
    this.#saveBtn = this.querySelector('.save_btn') as HTMLButtonElement;
    this.#discardBtn = this.querySelector('.discard_btn') as HTMLButtonElement;
    this.#editBtn = this.querySelector('.edit_btn') as HTMLButtonElement;
    this.#deleteBtn.onclick = (event) => {
      event.stopPropagation();
      const evt = new CustomEvent("delete");
      this.dispatchEvent(evt);
    }
    this.#saveBtn.onclick = (event) => {
      event.stopPropagation();
      const evt = new CustomEvent("save");
      this.dispatchEvent(evt);
    }
    this.#discardBtn.onclick = (event) => {
      event.stopPropagation();
      const evt = new CustomEvent("discard");
      this.dispatchEvent(evt);
    }
    this.#editBtn.onclick = (event) => {
      event.stopPropagation();
      const evt = new CustomEvent("edit");
      this.dispatchEvent(evt);
    }
    this.setEditMode(false);
  }
  
  setEditMode(editModeOn:boolean) {
    const group1 = [
      this.#deleteBtn,
      this.#editBtn
    ];
    const group2 = [
      this.#discardBtn,
      this.#saveBtn,
      this.#editModePanel
    ]
    setElementsHidden((editModeOn) ? group1:group2);
    setElementsVisible((editModeOn) ? group2:group1);    
  }
}

function setElementsHidden(eles:(HTMLElement|null)[]) {
  eles.forEach(ele => {
    if(!ele)
      return;
    ele.setAttribute("tabindex", "-1");
    ele.setAttribute("aria-hidden", "true");
  })
}
function setElementsVisible(eles:(HTMLElement|null)[]) {
  eles.forEach(ele => {
    if(!ele)
      return;
    ele.removeAttribute("tabindex");
    ele.removeAttribute("aria-hidden");
  });
}

globalThis.customElements.define("brew-record-context-menu", BrewRecordContextMenu);