import { TBrewRecord, TBrewResult, TBrewSource } from "../data/LoadRecentBest";
import { EditableCoffeeSource } from "./EditableCoffeeSource";
import './EditableCoffeeSource';
import { EditableBrewResults } from "./EditableBrewResults";
import './EditableBrewResults';
import './TimestampField';
import { TimestampField } from "./TimestampField";
import './BrewRecordContextMenu';
function cloneRecord(record:TBrewRecord) {
  return {
    source:{...record.source},
    result:{...record.result},
    config:{...record.config},
  }
}
export class EditableCompactBrewRecord extends EventTarget {
  //#deleteBtn;
  //#editBtn;
  //#saveBtn;
  #originalRecord;
  #record;
  #sourceEle:EditableCoffeeSource;
  #resultsEle:EditableBrewResults;
  #timestampEle:TimestampField;
  #rootEle:HTMLElement;
  #menu:BrewRecordContextMenu;
  #changed:boolean = false;

  constructor(rootEle:HTMLElement, initialRecord:TBrewRecord) {
    super();
    this.#rootEle = rootEle;

    rootEle.classList.add("EditableBrewRecord");
    rootEle.setAttribute("editmode", "false");
    rootEle.classList.add("compact");
    this.#record = cloneRecord(initialRecord);
    this.#originalRecord = initialRecord;
    this.#menu = document.createElement('brew-record-context-menu') as BrewRecordContextMenu;
    this.#rootEle.appendChild(this.#menu);
    this.#menu.addEventListener("delete",()=>{
      this.#delete();
    });
    this.#menu.addEventListener("save", () => {
      this.#save();
    });
    this.#menu.addEventListener("discard",()=>{
      this.#discard();
    });
    this.#menu.addEventListener("edit",()=>{
      this.#edit();
    });

    this.#timestampEle = document.createElement('timestamp-field') as TimestampField;
    this.#timestampEle.setAttribute("timestamp", this.#record.result.timestamp_epoch_ms.toString());
    rootEle.appendChild(this.#timestampEle);

    this.#sourceEle = document.createElement('editable-coffee-source') as EditableCoffeeSource;
    this.#sourceEle.source = initialRecord.source;
    rootEle.appendChild(this.#sourceEle);

    this.#resultsEle = document.createElement('editable-brew-results') as EditableBrewResults;
    this.#resultsEle.results = initialRecord.result;
    rootEle.appendChild(this.#resultsEle);

    this.#sourceEle.addEventListener("change", (event) => {
      this.#handleChange(event);
    });
    this.#resultsEle.addEventListener("change", (event) => {
      this.#handleChange(event);
    });
  }

  toggleEditMode() {
    const editable = this.#rootEle.getAttribute("editmode");
    this.#menu.setEditMode(editable !== "true");
    this.#rootEle.setAttribute("editmode", (editable === "true") ? "false":"true");
  }

  #handleChange(event:Event) {
    event?.stopPropagation();
    const closest = ((event.target) as HTMLElement)?.closest('[data-field]') as HTMLElement;
    if(!closest)
      return;
    const field = closest.dataset["field"];
    const value = (event.target as HTMLInputElement)?.value;
    switch(field){
      case "source-name": this.#record.source.name = value; break;
      case "source-grind": this.#record.source.grind = value; break;
      case "source-roast": this.#record.source.roast = value; break;

      case "results-strength": this.#record.result.strength = value as "weak"; break;
      case "results-extraction": this.#record.result.extraction = value as "under"; break;
      case "results-notes": this.#record.result.notes = value;break;


    }
    this.#changed = true;
  }

  get record() { return this.#record;}
  set record(record:TBrewRecord ) {
    this.#changed = false;
    this.#originalRecord = record;
    this.#record = cloneRecord(record);
    this.#rebuild();
  }

  #rebuild() {
    this.#sourceEle.source = this.#record.source;
    this.#resultsEle.results = this.#record.result;
    this.#timestampEle.setAttribute("timestamp", this.#record.result.timestamp_epoch_ms.toString());
  }

  #edit() {
    this.toggleEditMode();
  }

  #discard() {
    this.toggleEditMode();
    this.record = this.#originalRecord;
  }

  #delete() {
    const event = new CustomEvent("delete", {detail:{
      record:this.#originalRecord
    }});
    this.dispatchEvent(event);
  }

  #save() {
    this.record = this.#record;
    this.toggleEditMode();
    const event = new CustomEvent("update", {detail:{
      record:cloneRecord(this.#record)
    }});
    this.dispatchEvent(event);
  }
}