import { RoastString, RoastStrings } from "./Roast";
export function densityFromRoast_g_mL(roast:RoastString){
  //Numbers derived from: https://density.coffee/blog/why-measure-roasted-coffee-density/
  const index = RoastStrings.findIndex(value => value === roast);
  return 0.49-(index/(RoastStrings.length-1))*0.150;
}

export function gramsFromVolumeML(roast:RoastString, quantity_mL:number) {
  const density = densityFromRoast_g_mL(roast);
  return density * quantity_mL;
}

export function volumeFromGrams(roast:RoastString, quantity_g:number) {
  const density = densityFromRoast_g_mL(roast);
  return quantity_g / density;
}