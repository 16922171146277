import { CoffeeResultStorage } from "./CoffeeResults";
import { compareBrewResults } from "./buildBrewRatings";
export type TBrewResult = {
  strength:"weak" | "below-average" | "average" | "above-average" | "strong" | "excessive";
  extraction:"severely-under" | "under" | "good-under" | "good" | "good-over" | "over" | "severely-over";
  notes:string;
  timestamp_epoch_ms:number;
}
export type TBrewSource = {
  name:string;
  grind:string;
  roast:string;
}

export type TBrewRecord = {
  source:TBrewSource;
  config:BrewConfig;
  result:TBrewResult;
}

function lessThanSources(source1:TBrewSource, source2:TBrewSource) {
  const nameCmp = source1.name.toLocaleLowerCase().localeCompare(source2.name.toLowerCase());
  const roast = source1.roast.localeCompare(source2.roast);
  const grind = source1.grind.localeCompare(source2.grind);
  return (nameCmp < 0 
    || (nameCmp === 0 && roast < 0 )
    || (nameCmp === 0 && roast === 0 && grind < 0));
}
export function compareSources(source1:TBrewSource, source2:TBrewSource) {
  const firstLess = lessThanSources(source1, source2);
  const secondLess = lessThanSources(source2, source1);
  if(firstLess)
    return -1;
  if(secondLess)
    return 1;
  return 0;
}


export function findBestBrewForSource(brews:TBrewRecord[], source:TBrewSource) {
  const sourceMatches = brews.filter(brew => {
    return (compareSources(source, brew.source) === 0)
  });
  const best = sourceMatches.reduce((lastBrew:TBrewRecord|null, currentBrew:TBrewRecord) => {
    if(!lastBrew)
      return currentBrew;
    if(compareBrewResults(lastBrew.result, currentBrew.result) <=  0)
      return currentBrew;
    return lastBrew;
  }, null);
  return best;
} 

export function findMostRecentBrew(brews:TBrewRecord[]) {
  const latest = brews.reduce((priorRecord:TBrewRecord|null, currentRecord:TBrewRecord) => {
    if(!priorRecord)
      return currentRecord;
    return (priorRecord.result.timestamp_epoch_ms > currentRecord.result.timestamp_epoch_ms)
      ? priorRecord:currentRecord;
  },null);
  return latest;
}

