import { CBrewConfig, determineQuantityUnits, quantityUnitAbreviation } from "../data/BrewConfig.js";
import { densityFromRoast_g_mL } from "../data/Density.js";
import { convertUnitFromMl, convertUnitFromC, tempFtoC, FlozToML, roundToTenth } from "../utilities/ValueDisplayUtils.js";

function init_brew_config(rootEle, liveBrewConfig, useOldFields = false, useMetric=true, useMass=true, density=0.1) {
  const brewUnits = (!liveBrewConfig || !liveBrewConfig.value) ? null
    : new CBrewConfig(
      liveBrewConfig.value,
      density,
      (useMetric)? "metric":"std",
      (useMass) ? "mass":"volume");
  const oldSelector = (useOldFields) ? '.old_value' : '';
  const fieldPairs = [
    ["input_grounds_quantity_mL","grounds_quantity"],
    ["input_steep_water_quantity","steep_water_quantity"],
    ["select_temperature_control_type","steep_temp_control_type"],
    ["input_temperature_value","steep_temp"],
    ["input_temperature_cooldown","steep_cooldown_duration"],
    ["input_bloom_duration_s", "bloom_duration"],
    ["input_first_stir_duration_s","stir_duration"],
    ["input_steep_duration_s","steep_duration"],
    ["input_additional_water_quantity_mL","additional_water_quantity"],
  ];

  fieldPairs.forEach((pair) => {
    const element = rootEle.querySelector(`.${pair[0]}${oldSelector}`);
    const rawValue = (brewUnits) ? brewUnits.get([pair[1]]): "";
    const displayValue = (typeof rawValue === "number") ? roundToTenth(rawValue):rawValue;
    element.value = displayValue;
    if(useOldFields)
      return;

    element.onchange = (event) => {
      const rawValue = event.target.value
      const config = brewUnits.with(pair[1], rawValue);
      liveBrewConfig.update(config.config);
    };
  });
}


export class BrewConfigView {
  #rootEle;
  #liveBrewConfig;
  #liveOldBrewConfig;
  #useMetric;
  #useMass;
  #density;
  constructor(rootEle, liveBrewConfig, liveOldBrewConfig, useMetric = true, useMass=true, coffeeDensity=0.1) {
    this.#rootEle = rootEle;
    this.#density = coffeeDensity;
    this.#liveBrewConfig = liveBrewConfig;
    this.#liveOldBrewConfig = liveOldBrewConfig;
    this.#useMetric = useMetric;
    this.#useMass = useMass;
    this.redraw();
    this.#liveBrewConfig.addEventListener("update", (event) => {
      this.#updateUnits();
      this.#redrawConfig();
    });
    this.#liveOldBrewConfig.addEventListener("update", (event) => {
      this.#redrawOldConfig();
    });
  }

  #updateUnits() {
    const quantityUnits = determineQuantityUnits(this.#useMetric, this.#useMass);
    const quantityUnitLabels = this.#rootEle.querySelectorAll('.quantity-units-label');
    quantityUnitLabels.forEach(label => {
      const abr = quantityUnitAbreviation(quantityUnits);
      label.innerHTML = `(${abr})`;
    });
    const tempUnitLabels = this.#rootEle.querySelectorAll('.temp-units-label');
    tempUnitLabels.forEach(label => {
      label.innerHTML = (this.#useMetric) ? "(C)":"(F)";
    });
  }

  updateUnits(useMetric, useMass) {
    this.#useMetric = useMetric;
    this.#useMass = useMass;
    this.#updateUnits();
    this.redraw();
  }
  updateCoffeeDensity(coffeeDensity) {
    this.#density = coffeeDensity;
    this.redraw();
  }
  #redrawOldConfig() {
    init_brew_config(this.#rootEle, this.#liveOldBrewConfig, true, this.#useMetric, this.#useMass, this.#density);
  }

  #redrawConfig() {
    init_brew_config(this.#rootEle, this.#liveBrewConfig, false, this.#useMetric, this.#useMass,this.#density);
  }

  redraw() {
    this.#redrawConfig();
    this.#redrawOldConfig();
  }
}