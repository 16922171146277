/*const EXPIRING_ALERT_AUDIO = "resources/345684__provan9__radio-alert.wav";
this.#audio.src = EXPIRING_ALERT_AUDIO;
this.#audio.preload = true;
*/
export class CountdownTimerElement {
  #audioAlert;
  constructor(containerEle, audioAlert) {
    if(!containerEle)
      throw new Error("Invalid Parameter: containerEle does not exist");
    this.containerEle = containerEle;
    this.innerEle = containerEle.querySelector('.inner_timer');
    this.#audioAlert = audioAlert;
    this.remaining_ms = 0;
    this.expireAnimationDuration_ms = 3000;
    this.#redrawRemaining();
  }
  
  setAnimationParams(containerEle, duration, count) {
    containerEle.style.setProperty('--expire-animation-count', count);
    containerEle.style.setProperty('--expire-animation-duration', duration);
  }
  
  #clearTimers() {
    if(this.countdownInterval)
      clearInterval(this.countdownInterval);
    if(this.expireTimeout)
      clearTimeout(this.expireTimeout);
    if(this.#audioAlert)
      this.#audioAlert.reset();
  }

  #redrawRemaining() {
    this.innerEle.innerHTML = (this.remaining_ms/1000).toFixed(1);
  }
  #end() {
    this.#clearTimers();
    this.innerEle.classList.add("expired");
    this.innerEle.classList.remove("expiring", "active");
    if(this.onend)
      this.onend();
  }

  #startCountdownInterval() {
    const startTime = Date.now();
    var lastTime = Date.now();
    this.countdownInterval = setInterval(()=>{
      const currentTime = Date.now();
      this.remaining_ms = Math.max(0, this.remaining_ms - (currentTime-lastTime));
      lastTime = currentTime;
      this.#redrawRemaining();
      if(this.remaining_ms === 0)
        this.#end();
    }, 100);
  }

  #startExpiringTimeout() {
    const expiringAfter = Math.max(0, this.duration_ms-this.expireAnimationDuration_ms);
    this.expireTimeout = setTimeout(() => {
      this.innerEle.classList.add("expiring")
      if(this.#audioAlert) {
        try {
          this.#audioAlert.play();
        }catch(exception) {
          console.log("error starting playback");
        }
      }
      if(this.onexpiring) {
        this.onexpiring();
      }
    },expiringAfter);    
  }

  start() {
    this.stop();
    this.innerEle.classList.add("active");
    this.#startCountdownInterval();
    this.#startExpiringTimeout();
    if(this.onstart)
      this.onstart();
  }

  stop() {
    this.#clearTimers();
    this.innerEle.classList.remove("active", "expiring", "expired");
  }
  
  setTo(duration_ms) {
    this.stop();
    this.duration_ms = duration_ms;
    this.remaining_ms = duration_ms;
    this.#redrawRemaining();
  }
}