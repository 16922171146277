import { TBrewResult } from "../data/LoadRecentBest";
import { getExtractionRating, getStrengthRating } from "../data/buildBrewRatings";
const template = globalThis.document.createElement('template');
template.innerHTML = 
`
  <div class="field strength" data-field="results-strength">
    <label>Strength</label>
    <select aria-label="strength" name="strength">
      <option class="bad" value="weak">weak</option>
      <option class="bad" value="below-average">below-average</option>
      <option class="good" value="average">average</option>
      <option class="good" value="above-average">above-average</option>
      <option class="good" value="strong">strong</option>
      <option class="good" value="excessive">excessive</option>
    </select>
  </div>
  <div class="field extraction" data-field="results-extraction">
    <label>Extraction</label>
    <select aria-label="extraction" name="extraction" >
      <option class="bad" value="severely-under">severely-under</option>
      <option class="bad" value="under">under</option>
      <option class="good" value="good-under">good-under</option>
      <option class="good" value="good">good</option>
      <option class="good" value="good-over">good-over</option>
      <option class="bad" value="over">over</option>
      <option class="bad" value="severely-over">severely-over</option>
    </select>
  </div>
  <div class="field notes" data-field="results-notes">
    <label>Notes</label>
    <textarea placeholder="enter notes here"></textarea>
  </div>
`;
export class EditableBrewResults extends HTMLElement {
  #results:TBrewResult|null;
  #eleMap = new Map();
  constructor() {
    super();
    this.#results = null;
  }

  connectedCallback() {
    this.classList.add("EditableBrewResults");
    this.setAttribute("readonly", "true");

    this.innerHTML = "";
    this.appendChild(template.content.cloneNode(true));
    this.#eleMap.set("strength", this.querySelector('.strength select'));    
    this.#eleMap.set("extraction", this.querySelector('.extraction select'));
    this.#eleMap.set("notes", this.querySelector('.notes textarea'));
    
    (this.querySelector('.extraction select') as HTMLSelectElement)
      ?.addEventListener("change", (event) => {
        this.#updateRatings();
    });
    (this.querySelector('.strength select') as HTMLSelectElement)
      ?.addEventListener("change", (event) => {
        this.#updateRatings();
    });

    this.#fillValues();
  }

  #updateRatings() {
    const strength = this.querySelector('.strength select') as HTMLSelectElement;
    if(strength)
      setElementStatusClass(strength, getStrengthRating(strength.value));
    const extraction = this.querySelector('.extraction select') as HTMLSelectElement;
    if(extraction)
      setElementStatusClass(extraction, getExtractionRating(extraction.value));
  }
  set results(results:TBrewResult) {
    this.#results = results;
    if(this.isConnected)
      this.#fillValues();
  }

  get source():TBrewResult|null { return this.#results; }

  #fillValues() {    
    this.#eleMap.get("strength").value = this.#results?.strength || "";
    this.#eleMap.get("extraction").value = this.#results?.extraction || "";
    this.#eleMap.get("notes").value = this.#results?.notes || "";
    this.#updateRatings() ;
  }
}
function setElementStatusClass(ele:HTMLElement, status:"good"|"bad"|"") {
  ele.classList.remove("bad");
  ele.classList.remove("good");
  if(status === "good")
    ele.classList.add("good");
  if(status === "bad")
    ele.classList.add("bad");
}
globalThis.customElements.define("editable-brew-results", EditableBrewResults);