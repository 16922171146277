const template_CoffeeBrewRecord = {
  source:{
    name:"Generic Coffee",
    grind:"medium",
    roast:"dark"
  },
  config:{

  },
  result:{
    strength:"average",//["weak", "below-average", "average", "above-average", "strong", "excessive"]
    extraction:"under", //["severely-under", "under", "good-under", "good", "good-over", "over", "severely-over"]
    notes:"",
    timestamp_epoch_ms:Date.now(),
  }
}

Object.freeze(template_CoffeeBrewRecord);

export class CoffeeResultStorage extends EventTarget {
  #results;
  #storage;
  #key;
  constructor(storage, key) {
    super();
    this.#key = key;
    this.#storage = storage;
  }

  #loadResults() {
    const strResults = this.#storage.getItem(this.#key);
    if(strResults)
      this.#results = JSON.parse(strResults);
    else
      this.#results = {brews:[]};
    CoffeeResultStorage.#freezeResults(this.#results);
  }

  static #freezeResults(results) {
    Object.freeze(results);
    Object.freeze(results.brews);
    results.brews.forEach(brew => {
      Object.freeze(brew)
      Object.freeze(brew.result);
      Object.freeze(brew.source);
      Object.freeze(brew.config);
    });
  }
  
  get() {
    if(!this.#results)
      this.#loadResults();
    return this.#results;
  }

  #store() {
    const strResults = JSON.stringify(this.#results);
    this.#storage.setItem(this.#key, strResults);
  }

  #findBrewIndex(newBrew) {
    const oldIndex = this.#results.brews.findIndex((brew => brew.result.timestamp_epoch_ms === newBrew.result.timestamp_epoch_ms));
    return oldIndex;
  }

  remove(brew) {
    const oldIndex = this.#findBrewIndex(brew);
    const newResults = this.#replaceIndexWith(oldIndex, null);
    this.#replaceCurrentResults(newResults);
  }

  #replaceIndexWith(index, replacement) {
    const newCollection = {brews:[...this.#results.brews]};
    if(index < 0 && replacement)
      newCollection.brews.push(replacement);
    else if(index >= 0 && !replacement)
      newCollection.brews.splice(index, 1);
    else if(index >= 0 && replacement)
      newCollection.brews.splice(index, 1, replacement);
    return newCollection;
  }

  #replaceCurrentResults(newResults) {
    this.#results = newResults;
    CoffeeResultStorage.#freezeResults(this.#results);
    this.#store();
    const event = new Event("change", {value:this.#results, bubbles:false, cancelable:false});    
    this.dispatchEvent(event);
  }
  update(newBrew) {
    const oldIndex = this.#findBrewIndex(newBrew);    
    const newResults = this.#replaceIndexWith(oldIndex, newBrew);
    this.#replaceCurrentResults(newResults);
  }
}