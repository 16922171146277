import { Step } from "./Step.js";
import { FlozToML, mLToFloz, roundToTenth } from "../utilities/ValueDisplayUtils.js";
import { CBrewConfig, quantityUnitAbreviation } from "../data/BrewConfig.js";
import { densityFromRoast_g_mL } from "../data/Density.js";
const template = document.createElement('template');
template.innerHTML = 
`
<h2>Dilute to Taste</h2>
<div class="instructions">
  <p >Add additional hot water as necessary. Update value below with amount actually added to final cup. Press continue when finished.</p>
  <div class="not_ready">
    <button><span class="small material-symbols-outlined">
      replay_30
      </span>
    </button>
    <span>Coffee still under-extracted(sour)? If using a compatible press, reset press then tap button on left to continue extraction
    </span>
  </div>
</div>
<label>Water Added <span class="quantity-units-label">(mL)</span></label>
<input type="number"/>
<div><button aria-label="continue" class="continue_btn"><span class="material-symbols-outlined">arrow_forward</span></button></div>

`;
export class DiluteStep extends Step {
  #steepMoreRootEle;
  constructor(diluteStepEle, liveBrewConfig, sourceConfig, appConfig) {
    super(diluteStepEle);
    const density = densityFromRoast_g_mL(sourceConfig.roast);
    const cBrewConfig = new CBrewConfig(liveBrewConfig.value, density
      , appConfig.units, appConfig.quantityType
    );
    const abrev = quantityUnitAbreviation(cBrewConfig.quantityUnits);
    diluteStepEle.innerHTML = "";
    diluteStepEle.appendChild(template.content.cloneNode(true));

    this.waterAddedInputEle = diluteStepEle.querySelector('input');
    this.waterAddedInputEle.value = roundToTenth(cBrewConfig.additional_water_quantity);
    this.waterAddedUnitLabel = diluteStepEle.querySelector('.quantity-units-label');
    this.waterAddedUnitLabel.innerHTML = `(${abrev})`
    this.waterAddedInputEle.onchange = () => {
      const value = this.waterAddedInputEle.value;
      const newCBrewConfig = cBrewConfig.withAdditionalWaterQuantity(value);
      console.log(newCBrewConfig);
      liveBrewConfig.update(newCBrewConfig.config);
    }
    
    const continue_btn = diluteStepEle.querySelector('.continue_btn');
    continue_btn.onclick = () => { this.end(); };
    this.#steepMoreRootEle = diluteStepEle.querySelector('.not_ready');
    const steepMoreBtn = diluteStepEle.querySelector('.not_ready > button');

    steepMoreBtn.onclick = () => {
      this.hide();
      const event = new CustomEvent("continue_extraction", { detail: { duration_ms: 30000 } });
      this.dispatchEvent(event);
    };
  }
  setSteepMoreVisibility(visible) {
    if(visible)
      this.#steepMoreRootEle.classList.remove("hidden");
    else
      this.#steepMoreRootEle.classList.add("hidden");
  }
}
