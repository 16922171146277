import { FlozToML, ozToG } from "../utilities/ValueDisplayUtils";
import { mLToFloz,gToOz } from "../utilities/ValueDisplayUtils";

export function determineQuantityUnits(useMetric:boolean, useMass:boolean):TVolUnits|TWeightUnits {
  if(useMetric && useMass)
    return "grams";
  if(useMetric && !useMass)
    return "milliliters";
  if(!useMetric && !useMass)
    return "fluid-ounces";
  if(!useMetric && useMass)
    return "ounces";
  throw new Error("Invalid unit configuration.");
}

export function quantityUnitAbreviation(unit: TVolUnits|TWeightUnits) {
  switch(unit) {
    case "milliliters":return "mL";
    case "fluid-ounces": return 'fl-oz';
    case "grams": return 'g';
    case "ounces": return 'oz';
    default: throw new Error("Invalid parameter. Unit value unexpected: "+unit);
  }
}
type BrewConfig = {
  grounds_quantity_mL:number
  steep_water_quantity_mL:number
  steep_temp_control_type:"post_boil_cooldown"|"temperature"
  steep_temp_C:number;
  steep_cooldown_duration_s:number;

  bloom_duration_s:number;
  first_stir_duration_s:number;
  steep_duration_s:number;
  additional_water_quantity_mL:number;
};

export type TBrewConfig = BrewConfig;

export type TVolUnits = "milliliters" | "fluid-ounces"
export type TWeightUnits = "grams" | "ounces";
export type TUnits = "seconds" | TVolUnits | TWeightUnits;
  
export type TBrewConfigQuantityFields =
"grounds_quantity" |
"steep_water_quantity" |
"additional_water_quantity";

export type TBrewConfigFields =
  "steep_temp_control_type" |
  "steep_temp" |
  "steep_cooldown_duration" |
  "bloom_duration" |
  "stir_duration" |
  "steep_duration";
  
function convertMLToTargetUnits(value:number, units:TVolUnits|TWeightUnits, density_g_per_mL:number) {
  if(typeof value === "string")
    value = Number.parseFloat(value);
  if(typeof value !== "number")
    throw new Error("Invalid Type provided. Expected Number");
  switch(units) {
    case "milliliters":return value;
    case "fluid-ounces":return mLToFloz(value);
    case "ounces":return gToOz(value*density_g_per_mL);
    case "grams":return value * density_g_per_mL;
  }
}
function numberParseCheck(value:number) {
  if(typeof value === "string")
    value = Number.parseFloat(value);
  if(typeof value !== "number")
    throw new Error("Invalid Type provided. Expected Number");
  return value;
}
function convertSourceUnitsToML(value:number, units:TVolUnits|TWeightUnits, density_g_per_mL:number) {
  if(typeof value === "string")
    value = Number.parseFloat(value);
  if(typeof value !== "number")
    throw new Error("Invalid Type provided. Expected Number");
  switch(units){
    case "milliliters":return value;
    case "fluid-ounces":return FlozToML(value);
    case "ounces":return ozToG(value) / density_g_per_mL;
    case "grams":return value / density_g_per_mL;
    default:throw new Error("Invalid parameter, units not valid: "+units);
  }
}

export class CBrewConfig {
  #config:TBrewConfig;
  #coffeeDensity_g_per_mL:number;
  #unitSystem:"std"|"metric";
  #quantityType:"mass"|"volume";
  #quantityUnits:TVolUnits|TWeightUnits;
  get unitSystem() { return this.#unitSystem}
  get quantityType() { return this.#quantityType};
  get quantityUnits() { return this.#quantityUnits};
  get config(){ return {...this.#config}}
  get coffeeDensity_g_per_mL() { return this.#coffeeDensity_g_per_mL};

  constructor(config:TBrewConfig, coffee_density_g_per_mL:number, unitSystem:"std" | "metric", quantityType:"mass"|"volume") {
    this.#config = {...config};
    this.#coffeeDensity_g_per_mL = coffee_density_g_per_mL;
    this.#quantityType = quantityType;
    this.#unitSystem = unitSystem
    this.#quantityUnits = 
        (unitSystem === "std" && quantityType === "volume") ? "fluid-ounces" 
      : (unitSystem === "std" && quantityType === "mass") ? "ounces"
      : (unitSystem === "metric" && quantityType === "volume") ? "milliliters"
      : "grams";
  }

  get grounds_quantity():number {    
    const value = this.#config.grounds_quantity_mL;
    return convertMLToTargetUnits(value, this.#quantityUnits, this.#coffeeDensity_g_per_mL);
  }
  withGroundsQuantity(value:number):CBrewConfig {
    const storedValue = convertSourceUnitsToML(value, this.#quantityUnits, this.#coffeeDensity_g_per_mL);
    return this.#copyWithConfig({...this.#config, grounds_quantity_mL:storedValue });
  }
  
  get steep_water_quantity():number {
    const value = this.#config.steep_water_quantity_mL;
    return convertMLToTargetUnits(value, this.#quantityUnits, 1.0);
  }
  withSteepWaterQuantity(value:number):CBrewConfig {
    const storedValue = convertSourceUnitsToML(value, this.#quantityUnits, 1.0);
    return this.#copyWithConfig({...this.#config, steep_water_quantity_mL:storedValue });
  }

  get steep_temp_control_type() {
    return this.#config.steep_temp_control_type;
  }  
  withSteepTempControlType(type:"post_boil_cooldown"|"temperature") {
    return this.#copyWithConfig({...this.#config, steep_temp_control_type:type });
  }

  get steep_temp() {
    if(this.#unitSystem === "metric")
      return this.#config.steep_temp_C;
    return (this.#config.steep_temp_C * (9.0/5.0))+32;
  }
  withSteepTemp(value:number) {
    value = numberParseCheck(value);
    const storedValue = (this.#unitSystem === "metric")
      ? value
      : (value-32)*(5.0/9.0);
    return this.#copyWithConfig({...this.#config, steep_temp_C:storedValue });
  }

  get steep_cooldown_duration() {
    return this.#config.steep_cooldown_duration_s;
  }
  withSteepCooldownDuration(value:number) {
    value = numberParseCheck(value);
    return this.#copyWithConfig({...this.#config, steep_cooldown_duration_s:value});
  }

  get bloom_duration() {
    return this.#config.bloom_duration_s;
  }
  withBloomDuration(value:number) {
    value = numberParseCheck(value);
    return this.#copyWithConfig({...this.#config, bloom_duration_s:value});
  }

  get stir_duration() {
    return this.#config.first_stir_duration_s;
  }
  withStirDuration(value:number) {
    value = numberParseCheck(value);
    return this.#copyWithConfig({...this.#config, first_stir_duration_s:value});
  }

  get steep_duration() {
    return this.#config.steep_duration_s;
  }
  withSteepDuration(value:number) {
    value = numberParseCheck(value);
    return this.#copyWithConfig({...this.#config, steep_duration_s:value});
  }

  get additional_water_quantity():number {
    const value = this.#config.additional_water_quantity_mL;
    return convertMLToTargetUnits(value, this.#quantityUnits, 1.0);
  }

  get(field:string) {
    switch(String(field)) {
      case "grounds_quantity":return this.grounds_quantity;
      case "steep_water_quantity":return this.steep_water_quantity;
      case "steep_temp_control_type":return this.steep_temp_control_type;
      case "steep_temp":return this.steep_temp;
      case "steep_cooldown_duration":return this.steep_cooldown_duration;
      case "bloom_duration":return this.bloom_duration;
      case "stir_duration":return this.stir_duration;
      case "steep_duration":return this.steep_duration;
      case "additional_water_quantity":return this.additional_water_quantity;
      default: throw new Error("Invalid Field given:"+field);
    }
  }

  with(field:string, value:number|string) {
    if(field === "steep_temp_control_type" && (value === "post_boil_cooldown" || value === "temperature"))
        return this.withSteepTempControlType(value);
    value = numberParseCheck(value as number);
    switch(field) {
      case "grounds_quantity": return this.withGroundsQuantity(value);
      case "steep_water_quantity":return this.withSteepWaterQuantity(value);
      case "steep_temp":return this.withSteepTemp(value);
      case "steep_cooldown_duration":return this.withSteepCooldownDuration(value);
      case "bloom_duration":return this.withBloomDuration(value);
      case "stir_duration":return this.withStirDuration(value);
      case "steep_duration":return this.withSteepDuration(value);
      case "additional_water_quantity":return this.withAdditionalWaterQuantity(value);
      default: throw new Error("invalid field: "+field);
    }
  }
  withAdditionalWaterQuantity(value:number):CBrewConfig {
    value = numberParseCheck(value);
    const storedValue = convertSourceUnitsToML(value, this.#quantityUnits, 1.0);
    return this.#copyWithConfig({...this.#config, additional_water_quantity_mL:storedValue });
  }

  #copyWithConfig(config:TBrewConfig) {    
    return new CBrewConfig(config, this.#coffeeDensity_g_per_mL, this.#unitSystem, this.#quantityType);
  }
}

const exampleBrewConfig:BrewConfig = {
  grounds_quantity_mL:45,
  steep_water_quantity_mL:236,
  steep_temp_control_type:"post_boil_cooldown",
  steep_temp_C:0,
  steep_cooldown_duration_s:100,

  bloom_duration_s:60,
  first_stir_duration_s:30,
  steep_duration_s:30,
  additional_water_quantity_mL:0
}

