const notificationTemplate = document.createElement('template');
notificationTemplate.innerHTML =
  `<button class="update">
    <span class="material-symbols-outlined">system_update</span>
  </button>

  <div class="popup-menu">  
    <span>App Updated. Click reload button to switch to latest version.</span>
    <button class="reload">
      <span class="material-symbols-outlined">frame_reload</span>
    </button>
  </div>
`;

export class NotificationIconUI extends HTMLElement{
  
  constructor() {
    super();
    this.appendChild(notificationTemplate.content.cloneNode(true));
    const menuBtn = this.querySelector("button.update");
    const reloadBtn = this.querySelector("button.reload");
    const updateMenu = this.querySelector(".popup-menu");    
    updateMenu.classList.add("hidden");
    const opened = false;
    menuBtn.onclick = (event) =>{
      updateMenu.classList.toggle("hidden");
    }
    reloadBtn.onclick = () => {
      window.location.reload();
    };
    if(this.getAttribute("update_pending"))
      this.classList.remove("hidden");
    else
      this.classList.add("hidden");
  }

  static get observedAttributes() {
    return ["update_pending"];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if(name === "update_pending") {
      if(newValue)
        this.classList.remove("hidden");
      else
        this.classList.add("hidden");
    }
  }
}

customElements.define("update-notification-icon-ui", NotificationIconUI);
