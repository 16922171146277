export type RoastString = 
    "extra-light" 
  | "light" 
  | "medium-light"
  | "medium"
  | "medium-dark"
  | "dark"
  | "extra-dark";

export const RoastStrings:RoastString[] = [
  "extra-light",
  "light" ,
  "medium-light",
  "medium",
  "medium-dark",
  "dark",
  "extra-dark",
];
