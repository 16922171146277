import {Brewer} from './brew_steps/brew.js';
import { CoffeeResultStorage } from './data/CoffeeResults.js';
import { HistoryDialog } from './ui/HistoryDialog.js';
import { HistoryRecordView } from './ui/HistoryRecord.js';
import { BrewConfigView } from './ui/BrewConfigView.js';
import { SourceConfigView } from './ui/SourceConfigView.js';
import { RadioGroup } from './ui_components/RadioGroup.js';
import { LiveValue } from './utilities/LiveValue.js';
//import { NotificationIconUI } from './ui/UpdateNotification.js';
import './ui/UpdateNotification.js';
import { TargetDensityUI } from './ui/TargetDensity.js';
import { compareSources, findBestBrewForSource, findMostRecentBrew } from './data/LoadRecentBest.js';
import { buildLocalBrewAppConfigStorage, buildMemoryBrewAppConfigStorage, buildSessionBrewAppConfigStorage } from './data/ConfigStorage.js';
import './ui_components/SoundStatus';
import './ui/BrewConfigViewElement';
import { SoundStatus } from './ui_components/SoundStatus';
import { BrewConfigViewElement } from './ui/BrewConfigViewElement';
import { CBrewConfig } from './data/BrewConfig.js';
import { densityFromRoast_g_mL } from './data/Density.js';
import './ui_components/InformationPopup';
var notificationSent = false;
function notifyUserToReloadForUpdate() {
  if(notificationSent)
    return;
  notificationSent = true;
  
  const updater = document.querySelector("update-notification-icon-ui");
  updater.setAttribute("update_pending", "true");  
}

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.addEventListener("message", (message) =>{
    if(!notificationSent)
      console.log("message:",message);
    if(message?.data?.updated) {
      notifyUserToReloadForUpdate();
      return;
    }
  });
  // Register a service worker hosted at the root of the
  // site using the default scope.
  navigator.serviceWorker.register("sw.js").then(
  
    (registration) => {
      console.log("Service worker registration succeeded:", registration);
    },
    (error) => {
      console.error(`Service worker registration failed: ${error}`);
    },
  );
  
} else {
  console.error("Service workers are not supported.");
}



const template_CoffeeSource = {
  name:"Generic Coffee",
  roast:"medium",
  grind:"medium",
  density_g_mL:undefined,
}

/*const template_BrewConfig = {
  grounds_quantity_units:"mL",
  grounds_quantity:30,
  seep_water_quantity_mL:136,
  temp_control_type:"post_boil_cooldown",
  temp_control_value:190,
  temp_control_cooldown:15,
  initial_stir_duration_s:15,
  hold_duration_s:10,
  final_stir_duration_s:0,
  additional_water_quantity_mL:100
}*/

const template_BrewConfig = {
  grounds_quantity_mL:45,
  steep_water_quantity_mL:236,
  steep_temp_control_type:"post_boil_cooldown",
  steep_temp_C:93,
  steep_cooldown_duration_s:120,

  bloom_duration_s:60,
  first_stir_duration_s:30,
  steep_duration_s:30,
  additional_water_quantity_mL:0
}

Object.freeze(template_BrewConfig);
Object.freeze(template_CoffeeSource);

var brewer = null;
var storage = null;
var historyDialog = null;
var historyRecordView = null;

var historyRecordOwner = new LiveValue(null);

function rebuildHistoryDialog(records, source = null) {
  const rootEle = document.querySelector('#history_dialog');
  historyDialog = new HistoryDialog(rootEle, records, source);
  historyDialog.addEventListener("selected", (event) => {
    historyRecordOwner.update(event.detail);
  });
  historyDialog.addEventListener("deleteBrew", (event) => {
    storage.remove(event.detail);
  });
  historyDialog.addEventListener("updateBrew", (event) => {
    storage.update(event.detail);
  });
  //ootEle.showModal();
}
const appConfig = buildLocalBrewAppConfigStorage("0.1")
  || buildSessionBrewAppConfigStorage("0.1")
  || buildMemoryBrewAppConfigStorage("0.1");

var brewConfigView = null;
//var activeSourceConfig = {...template_CoffeeSource};
var activeSourceConfigOwner = new LiveValue({...template_CoffeeSource}, (obj) => {if(obj) Object.freeze(obj)});
var oldBrewConfigOwner = new LiveValue(null, (obj) => {if(obj) Object.freeze(obj)});
var sourceConfigView = null;
var activeBrewConfigOwner = new LiveValue({...template_BrewConfig}, (obj) => {if(obj) Object.freeze(obj)});
var liveOldSourceConfig = new LiveValue(null, (obj) => {if(obj) Object.freeze(obj)});
var unitsSetting = null;
var bestBrewSetting = null;
var lastSourceSetting = null;
var quantitySetting = null;
var targetDensityUI = null;

/*var brewConfigUnits = null;
var oldBrewConfigUnits = null;

activeBrewConfigOwner.addEventListener("update", (event) => {
  const coffeeRoast = activeSourceConfigOwner.value?.roast;
  if(!coffeeRoast)
    throw new Error("No source set");
  const density = densityFromRoast_g_mL(coffeeRoast);
  brewConfigUnits = new CBrewConfig(activeBrewConfigOwner.value, density,
    appConfig.units, "volume");
});
oldBrewConfigOwner.addEventListener("update", (event) => {
  const coffeeRoast = activeSourceConfigOwner.value?.roast;
  if(!coffeeRoast)
    throw new Error("No source set");
  const density = densityFromRoast_g_mL(coffeeRoast);
  oldBrewConfigUnits = new CBrewConfig(oldBrewConfigOwner.value, density,
    appConfig.units, "volume");
});*/

function changeUnits(newUnits) {
  const styleRoot = document.querySelector(':root');
  if(newUnits === "metric") {
    styleRoot.style.setProperty('--units-metric-display', 'initial')
    styleRoot.style.setProperty('--units-std-display', 'none')
  } else if(newUnits === "std") {
    styleRoot.style.setProperty('--units-metric-display', 'none')
    styleRoot.style.setProperty('--units-std-display', 'initial')
  } else
    console.error("Invalid Units Setting: "+newUnits);
}

appConfig.addEventListener("units", (event)=>{
  changeUnits(event.detail);
  //brewConfigView.updateUseMetric(event.detail === "metric");
  brewConfigView.updateUnits(event.detail === "metric", appConfig.quantityType === "mass");
});
appConfig.addEventListener("quantity_type", (event) => {
  brewConfigView.updateUnits(appConfig.units === "metric", appConfig.quantityType === "mass");
});
/*function updateBrewConfigElement() {
  const element = document.querySelector('brew-config-view');
  const brewConfig = new CBrewConfig(
    activeBrewConfigOwner.value, (v) => activeBrewConfigOwner.update(v),
    oldBrewConfigOwner.value, (v) => oldBrewConfigOwner.update(v));
}
activeBrewConfigOwner.addEventListener("update", event => {
  updateBrewConfigElement();
});
oldBrewConfigOwner.addEventListener("update", event => {
  updateBrewConfigElement();
});*/

window.onload = () => {
  const useMetric = appConfig.units === "metric";
  const useMass = appConfig.quantityType === "mass";
  changeUnits(appConfig.units);
  unitsSetting = new RadioGroup(document.querySelector('.units-setting'), appConfig.units);
  unitsSetting.addEventListener("changed", (event) => {
    appConfig.units = event.detail;
  });
  bestBrewSetting = new RadioGroup(document.querySelector('.autoload-best-setting'), appConfig.autoloadBestBrew);
  bestBrewSetting.addEventListener("changed", (event) => {
    appConfig.autoloadBestBrew = event.detail;
  });

  lastSourceSetting = new RadioGroup(document.querySelector('.autoload-last-setting'), appConfig.autoloadLastSource);
  lastSourceSetting.addEventListener("changed", (event) => {
    appConfig.autoloadLastSource= event.detail;
  });
  quantitySetting = new RadioGroup(document.querySelector('.quantity-type-setting'), appConfig.quantityType);
  quantitySetting.addEventListener("changed", event => {
    appConfig.quantityType = event.detail;
  });
  const coffeeDensity = densityFromRoast_g_mL(activeSourceConfigOwner.value?.roast || "medium");
  brewConfigView = new BrewConfigView(document.querySelector('#brew_config_root')
    , activeBrewConfigOwner, oldBrewConfigOwner, useMetric, useMass, coffeeDensity);
  sourceConfigView = new SourceConfigView(document.querySelector('#coffee_source_root')
    , activeSourceConfigOwner, liveOldSourceConfig);
  const historyDialog = document.querySelector('#history_dialog');
  const historyBtn = document.querySelector('.history .history_btn');
  historyBtn.onclick = () => {historyDialog.showModal();};

  const cloneBtn = document.querySelector("#brew_config_root header button");
  cloneBtn.onclick = () => {
    const brewConfig = historyRecordOwner.value?.config;
    activeBrewConfigOwner.update({...brewConfig})
  };

  const brewBtn = document.querySelector('#start_brew_btn');
  brewBtn.onclick=startBrewer;
  //window.sessionStorage.removeItem("CoffeResultStorage");
  storage = new CoffeeResultStorage(window.localStorage, "CoffeResultStorage");
  const values = storage.get();

  const updateStorageDependents = (brews, sourceConfig) => {
    rebuildHistoryDialog(brews, sourceConfig);
    const oldSources = (!brews) ? []:
      brews.map(brew => brew.source)
      .sort(compareSources).reduce((prev, curr)=>{
        if(prev.length === 0 || compareSources(prev[prev.length-1], curr) !== 0)
          prev.push(curr);
        return prev;
    }, []);
    sourceConfigView.updateOldSources(oldSources);
  }

  updateStorageDependents(storage.get().brews, activeSourceConfigOwner.value);

  storage.addEventListener("change", (event) => {
    updateStorageDependents(storage.get().brews, activeSourceConfigOwner.value);
  });
  activeSourceConfigOwner.addEventListener("update", (event) => {
    rebuildHistoryDialog(storage.get().brews, activeSourceConfigOwner.value)
    const density = densityFromRoast_g_mL(activeSourceConfigOwner.value?.roast || "medium");
    brewConfigView.updateCoffeeDensity(density);
  });

  historyRecordView = new HistoryRecordView(document.querySelector('.history .record'), null);
  historyRecordView.addEventListener("clear", () => {
    historyRecordOwner.update(null);
  });

  historyRecordOwner.addEventListener("update", (event) => {
    historyRecordView.updateRecord(event.detail?.result);
    oldBrewConfigOwner.update(event.detail?.config);
    liveOldSourceConfig.update(event.detail?.source);    
  });

  historyRecordOwner.addEventListener("update", (event) => {
    const ele = document.querySelector(':root');
    const displayValue = (event.detail) ? 'initial':'none';
    ele.style.setProperty('--old_value_display', displayValue);
  });

  targetDensityUI = new TargetDensityUI(
    document.querySelector('.target_density_ui'),
    activeBrewConfigOwner,
    activeSourceConfigOwner,
    oldBrewConfigOwner,
    liveOldSourceConfig,
  );
  
  if(appConfig.autoloadLastSource) 
    loadRecent();
  if(appConfig.autoloadLastSource && appConfig.autoloadBestBrew)
    loadBest();

}
function loadRecent() {
  const brews = storage.get().brews;
  const mostRecent = findMostRecentBrew(brews);
  if(!mostRecent)
    return;
  activeSourceConfigOwner.update(mostRecent.source);
}

function loadBest() {
  const brews = storage.get().brews;
  const best = findBestBrewForSource(brews, activeSourceConfigOwner.value);
  if(!best)
    return;
  historyRecordOwner.update(best);
  activeBrewConfigOwner.update(best.config);
}

function configureBrewerModal() {
  const source = activeSourceConfigOwner.value;
  const brewConfig = activeBrewConfigOwner.value;
  brewer = new Brewer(source, brewConfig, "brew_dialog", storage, appConfig.units === "metric", appConfig);
}

function startBrewer() {
  configureBrewerModal();
  brewer.start();
}