import { TBrewSource } from "../data/LoadRecentBest";

function buildNameGroup(sources:TBrewSource[], handleSelected:(selected:TBrewSource)=>void) {
  if(!sources || sources.length === 0)
    return document.createElement('div');
  const  groupEle = document.createElement('div');
  groupEle.classList.add("name-group");
  groupEle.innerHTML =
    `<span class="name">${sources[0].name}</span>`;
  sources.forEach(curr => {
    const ele = buildInstance(curr, handleSelected);
    groupEle.appendChild(ele);
  });
  groupEle.onpointerdown = (event) =>{
    event.preventDefault();
    event.stopPropagation();
  }
  groupEle.ontouchstart = (event) => {
    event.stopPropagation();
    event.preventDefault();
  }
  return groupEle;
}

function buildInstance(source:TBrewSource, handleSelected:(selected:TBrewSource)=>void) {
  const element = document.createElement('div');
  const str = 
  `
  <div class="instance">
    <span class="roast">${source.roast}</span>
    <span class="grind">${source.grind}</span>
  </div>
  `
  element.innerHTML = str;
  const result = element.children.item(0)?.cloneNode(true) as HTMLElement;
  
  result.onpointerdown = (event) => {
    event.stopPropagation();
    handleSelected(source);
  }
  result.ontouchstart = (event) => {
    event.stopPropagation();
  }
  return result;
}

const template = document.createElement('template');
template.innerHTML = 
`
<div class="items">
  <div class="name-group'>
    <span class="name"></span>
    <div class="instance">
      <span class="roast"></span>
      <span class="grind"></span>
    </div>
    <div class="instance">
      <span class="roast"></span>
      <span class="grind"></span>
    </div>
  </div>
</div>
`

function groupByName(sources:TBrewSource[]):TBrewSource[][] {
  const sorted = sources.sort((s1, s2) => s1.name.toLocaleLowerCase().localeCompare(s2.name.toLowerCase()));
  const result:TBrewSource[][] = sorted.reduce((prev, curr) => {
    if(prev.length === 0) {
      prev.push([curr]);
      return prev;
    }
    const last = prev[prev.length-1];
    if(last[0].name.toLocaleLowerCase() !== curr.name.toLocaleLowerCase())
      prev.push([curr]);
    else
      last.push(curr);
    return prev;
  },[]as TBrewSource[][]);
  return result;
}

export class NameGroupedSuggestionBox extends EventTarget {
  #containerEle:HTMLElement;
  #inputEle:HTMLInputElement;

  constructor(inputEle:HTMLInputElement, containerEle:HTMLElement, oldSources:TBrewSource[]) {
    super();
    this.#containerEle = containerEle;
    this.#inputEle = inputEle;
    this.#inputEle.onfocus = () =>  {
      containerEle.classList.remove("hidden");
    };
    this.#inputEle.onblur = () =>{
      containerEle.classList.add("hidden");
    };
    this.#layout(oldSources);
  }
  
  update(sources:TBrewSource[]) {
    this.#layout(sources);
  }

  #layout(oldSources:TBrewSource[]) {
    this.#containerEle.innerHTML = "";
    if(!oldSources || oldSources.length === 0)
      return;
    const handleSelected = (selectedSource:TBrewSource) => {
      const event = new CustomEvent("selected", {detail:selectedSource});
      this.dispatchEvent(event);
    };
    const groupedByName:TBrewSource[][] = groupByName(oldSources);
    const groups = groupedByName.map(group =>{
      return buildNameGroup(group, handleSelected);
    });
    groups.forEach(groupEle => {
      this.#containerEle.appendChild(groupEle);
    })
  }
}