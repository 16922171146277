import { convertUnitFromMl } from "../utilities/ValueDisplayUtils.js";
import { TimerStep } from "./TimerStep.js";
import { AdditionalSteepStep } from "./AdditionalSteepStep.js";
import { factory_HeatStep, PressStep } from "./HeatStep.js";
import { ResultsStep } from "./ResultsStep.js";
import { DiluteStep } from "./DiluteStep.js";
import { AudioAlert } from "../ui_components/AudioAlert.js";
import { LiveValue } from "../utilities/LiveValue.js";
const EXPIRING_ALERT_AUDIO = "resources/345684__provan9__radio-alert.wav";

function configureSoundStatus(appConfig, soundStatusEle) {
  soundStatusEle.setSoundStatus(appConfig.soundEnabled);
  soundStatusEle.addEventListener("change", (event) => {
    appConfig.soundEnabled = event.detail.soundEnabled;
  });
}

export class Brewer {
  #setupAudioAlert(appConfig) {
    this.audioEle = this.dialog.querySelector('audio');
    this.audioStatus = this.dialog.querySelector('sound-status');
    configureSoundStatus(appConfig, this.audioStatus);
    this.audioAlert = new AudioAlert(this.audioEle, EXPIRING_ALERT_AUDIO, appConfig.soundEnabled);
    appConfig.addEventListener("sound_enabled", (event) => {
      this.audioAlert.setEnabled(event.detail);
    });
  }

  constructor(sourceConfig, inputBrewConfig, dialogId, storage, useMetric=true, appConfig) {
    const brewConfig = {...inputBrewConfig};
    const liveBrewConfig = new LiveValue(brewConfig);
    this.dialogId = dialogId;
    this.dialog = document.querySelector(`#${dialogId}`);
    this.cancelBtn = this.dialog.querySelector('header .zone_clear_btn');
    this.sourceConfig = sourceConfig;
    this.brewConfig = brewConfig;
    this.#setupAudioAlert(appConfig);
    
    const storageFn = (brewResult) => {
      if(!storage)
        return;
      const brewInstance = {
        source:{...this.sourceConfig},
        config:liveBrewConfig.value,
        result:brewResult
      }
      storage.update(brewInstance);
    }
 
    this.steps = [
      factory_HeatStep(this.dialog.querySelector('.steps > .heat_water'), brewConfig, useMetric,this.audioAlert),
      new TimerStep(this.dialog.querySelector('.steps > .bloom'), brewConfig.bloom_duration_s*1000, (brewConfig.bloom_duration_s*1000) > 0 ? 3000:0, this.audioAlert),
      new TimerStep(this.dialog.querySelector('.steps > .turbulence'), brewConfig.first_stir_duration_s*1000, (brewConfig.first_stir_duration_s*1000) > 0 ? 3000:0, this.audioAlert),
      new TimerStep(this.dialog.querySelector('.steps > .steep_hold'), brewConfig.steep_duration_s*1000, (brewConfig.steep_duration_s*1000) > 0 ? 3000:0, this.audioAlert),
      new PressStep(this.dialog.querySelector('.steps > .press'), brewConfig),
      new DiluteStep(this.dialog.querySelector('.steps > .dilute'), liveBrewConfig, sourceConfig, appConfig),
      new ResultsStep(this.dialog.querySelector('.steps > .results'), storageFn)
    ];
    //const additionalSteepStep = new AdditionalSteepStep(this.dialog.querySelector('.steps > .additional_steep'), 15000, 3000);
    const additionalSteepStep = AdditionalSteepStep.build(this.dialog.querySelector('.steps > .additional_steep'), 15000, 3000, this.audioAlert);
    const diluteStep = this.steps[5];
    diluteStep.addEventListener("continue_extraction", (event) =>{
      diluteStep.hide();
      console.log(Number.parseInt(this.brewConfig.steep_duration_s));
//      console.log((event.detail.duration_ms/1000));
      this.brewConfig.steep_duration_s = Number.parseInt(this.brewConfig.steep_duration_s) + event.detail.duration_ms/1000;
      console.log(this.brewConfig.steep_duration_s);
      additionalSteepStep.timer.setTo(event.detail.duration_ms);
      additionalSteepStep.start();
    });

    additionalSteepStep.onended = () => {
      additionalSteepStep.hide();
      this.steps[4].start();
    }

    this.steps.forEach((step, index) => {
      if(index === 0)
        return;
      this.steps[index-1].onended = ()=>{this.steps[index].start()};
    });

    this.steps[this.steps.length-1].onended = () => {this.hide();};
    this.cancelBtn.onclick=() => {this.stop();}
  }

  show() {
    this.dialog.showModal();
  }

  start() {
    this.show();
    this.steps[0].start();
  }  

  hide() {
    this.dialog.close();
  }
  stop() {
    this.hide();
    this.steps.forEach(step => step.stop());
  }
}