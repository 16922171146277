export class MutuallyExclusiveClassGroup {
  #observer;
  #className;
  #lastActive;
  constructor(elements, className) {
    this.#className = className;
    this.#lastActive = null;
    this.#observer = new MutationObserver((mutationList) => { this.#triggered(mutationList); });
    elements.forEach((element) => {
      this.#observer.observe(element, { subtree: true, attributes: true, attributeFilter: ["class"] });
    });
  }
  #replacedActive(target) {
    if (!target.classList.contains(this.#className))
      return false;
    this.#lastActive?.classList.remove(this.#className);
    this.#lastActive = target;
    return true;
  }
  #removedActive(target) {
    if (target !== this.#lastActive)
      return false;
    this.#lastActive = null;
    return true;
  }

  #triggered(mutationList) {
    mutationList.forEach(mutation => {
      const { target } = mutation;
      const containsSubject = target.classList.contains(this.#className);
      if (this.#replacedActive(target))
        return;
      this.#removedActive(target);
    });

  }
}
