import { TimerStep } from "./TimerStep";
const template = document.createElement("template");

//<div class="hidden additional_steep">
template.innerHTML = 
`
  <h2>Extra Steep Time</h2>
  <div class="instructions">
    <p>Let coffee steep undisturbed till timer expires. Press Stop button to end early.</p>
  </div>
  <div class="CountdownTimerContainer">
    <div id="steep" class="inner_timer"></div>
  </div>
  <!--button><span class="small material-symbols-outlined">stop</span></button-->
`

export class AdditionalSteepStep extends TimerStep {
  constructor(rootEle, duration_ms, delay_ms = 0, audioAlert) {
    super(rootEle, duration_ms, delay_ms, audioAlert);    
    /*const stopBtn = rootEle.querySelector('button');
    stopBtn.onclick = (event) => {
      event.stopPropagation();
      this.end();
    }*/
  }

  static build(rootEle, duration_ms, delay_ms, audioAlert = null) {
    rootEle.innerHTML = "";
    rootEle.appendChild(template.content.cloneNode(true));
    return new AdditionalSteepStep(rootEle, duration_ms, delay_ms, audioAlert);
  }
}
