export function mLToFloz(value) {
  return value * 0.033814;
}
export function FlozToML(value) {
  return value*29.5735;
}
export function gToOz(value) {
  return value/28.3495;
}
export function ozToG(value) {
  return value * 28.3495;
}
export function tempFtoC(value) {
  return (value-32)*(5/9);
}
export function tempCtoF(value) {
  return (value*(9/5))+32;
}
export function convertUnitFromC(useMetric, value, round=false) {
  const result = (useMetric) ? value : tempCtoF(value);
  return (round) ? roundToTenth(result):result;
}

export function convertUnitFromMl(useMetric, value, round=false) {
  const result = (useMetric) ? value : mLToFloz(value);
  return (round) ? roundToTenth(result):result;
}

export function roundToTenth(number) {
  return (Math.round(number*10)/10);
}