
export class AudioAlert {
  #audioEle;
  #enabled;
  constructor(ele, source, enabled) {
    this.#audioEle = ele;
    this.#enabled = !!ele && enabled;
    if (!ele)
      return;
    this.#audioEle.src = source;
    this.#audioEle.preload = true;
  }

  setEnabled(enabled) {
    if (!this.#audioEle)
      return;
    this.#enabled = enabled;
    if (!enabled)
      this.reset();
  }
  reset() {
    if (!this.#audioEle)
      return;
    if (this.#audioEle.paused)
      return;
    this.#audioEle.pause();
    this.#audioEle.currentTime = 0;
  }

  play() {
    if (!this.#enabled)
      return;
    if (this.#audioEle.paused)
      this.#audioEle.play();
  }
}
