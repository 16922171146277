export class RadioGroup extends EventTarget {
  #value;
  #inputs;
  constructor(containerEle, startValue) {
    super();
    this.#inputs = Array.from(containerEle.querySelectorAll('input[type="radio"]'));
    RadioGroup.#init(this.#inputs, startValue);
    this.#populateValue();
    containerEle.onchange = () => {
      this.#populateValue();
      const event = new CustomEvent("changed", {detail:this.#value});
      this.dispatchEvent(event);
    }
  }
  static #init(inputs, startValue) {
    if(typeof startValue === 'undefined')
      return;
    const selected = inputs.find(input => input.value === startValue);
    if(!selected)
      return;
    selected.checked = true;
  }

  #populateValue() {
    const checked = this.#inputs.find(input => !!input.checked );
    this.#value = (checked) ? checked.value : undefined;
  }
  get value() { return this.#value;}

}