export class LiveValue extends EventTarget {
  #value;
  #freezeFn;
  constructor(value = undefined, freezeFn = null) {
    super();
    this.#value = value;
    this.#freezeFn = freezeFn;
    if (freezeFn)
      freezeFn(value);
  }

  get value() { return this.#value; };

  update(newValue) {
    this.#value = newValue;
    if (this.#freezeFn)
      this.#freezeFn(newValue);
    const event = new CustomEvent("update", { detail: newValue });
    this.dispatchEvent(event);
  }
}
