import { NameGroupedSuggestionBox } from "../ui_components/NameGroupedSuggestionBox.js";
import { SourceConfigNameSelectionBox } from "../ui_components/SuggestionBox.js";

function updateSourceField(liveSource, field, newValue) {
  const newSource = {...liveSource.value};
  newSource[field] = newValue;
  liveSource.update(newSource);
}

function init_source_config(rootEle, liveSourceConfig, useOldFields = false, notifyChange, oldSources = null) {
  const oldSelector = (useOldFields) ? '.old_value' : '';
  const sourceConfig = liveSourceConfig?.value;
  const fieldPairs = [
    ["input_name", "name"],
    ["select_roast", "roast"],
    ["select_grind", "grind"],
  ]

  fieldPairs.forEach((pair) => {
    const element = rootEle.querySelector(`.${pair[0]}${oldSelector}`);
    element.value = (sourceConfig) ? sourceConfig[pair[1]]:"";
    if(useOldFields)
      return;
    
    element.onchange = (event) => {
      event.stopPropagation();
      if(!sourceConfig)
        return;
      updateSourceField(liveSourceConfig, pair[1], event.target.value);
    };
  });
}

export class SourceConfigView {
  #rootEle;
  #liveSourceConfig;
  #liveOldSourceConfig;
  #oldSources;
  #suggestionBox;

  constructor(rootEle, sourceConfigOwner, liveOldSourceConfig = null, pastSources = null) {
    this.#rootEle = rootEle;
    this.#liveSourceConfig = sourceConfigOwner;
    this.#liveOldSourceConfig = liveOldSourceConfig;
    this.#oldSources = pastSources;
    this.#liveSourceConfig.addEventListener("update", () => {
      this.redraw();
    });
    this.#liveOldSourceConfig.addEventListener("update", () => {
      this.redraw();
    });
    this.redraw();
    this.redrawSuggestionBox();    
  }
  
  redrawSuggestionBox() {
    const suggestionContainer = this.#rootEle.querySelector('.name-suggestion-container');
    
    const targetEle = this.#rootEle.querySelector('.field .new_value.input_name');
    this.#suggestionBox = new NameGroupedSuggestionBox(targetEle, suggestionContainer, this.#oldSources);
    this.#suggestionBox.addEventListener("selected", (event) => {
      this.#liveSourceConfig.update({...event.detail});
    });
  }

  updateOldSources(updatedSources) {
    this.#oldSources = updatedSources;
    this.redrawSuggestionBox();
  }

  redraw() {
    init_source_config(this.#rootEle, this.#liveSourceConfig, false, (updatedSourceConfig) => {      
      this.#liveSourceConfig.update(updatedSourceConfig);
    }, this.#oldSources);
    init_source_config(this.#rootEle, this.#liveOldSourceConfig, true, () => {});
  }
}