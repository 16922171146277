import { TBrewSource } from "../data/LoadRecentBest";

function getIdGeneratorFn(bytes:number) {
  
}


const template = globalThis.document.createElement('template');
template.innerHTML = 
`
  <div class="field name" data-field="source-name">
    <label>Name</label>
    <input aria-label="Name" type="text"></input>
  </div>
  <div class="field roast" data-field="source-roast">
    <label >Roast</label>
    <select aria-label="roast" name="roast">
      <option value="extra-light">Extra-Light</option>
      <option value="light">Light</option>
      <option value="medium-light">Medium-Light</option>
      <option value="medium">Medium</option>
      <option value="medium-dark">Medium-Dark</option>
      <option value="dark">Dark</option>
      <option value="extra-dark">Extra-Dark</option>
    </select>
  </div>
  <div class="field grind" data-field="source-grind">
    <label>Grind</label>
    <select aria-label="grind" name="grind">
      <option value="fine">Fine</option>
      <option value="medium-fine">Medium-Fine</option>
      <option value="medium">Medium</option>
      <option value="medium-course">Medium-Course</option>
      <option value="course">Course</option>
    </select>
  </div>
`
export class EditableCoffeeSource extends HTMLElement {
  #source:TBrewSource|null;
  #eleMap = new Map();
  constructor() {
    super();
    this.#source = null;
  }

  connectedCallback() {
    this.classList.add("EditableCoffeeSource");
    this.innerHTML = "";
    this.appendChild(template.content.cloneNode(true));
    this.#eleMap.set("name", this.querySelector('.name input'));    
    this.#eleMap.set("roast", this.querySelector('.roast select'));
    this.#eleMap.set("grind", this.querySelector('.grind select'));
    this.#fillValues();
  }

  set source(source:TBrewSource) {
    this.#source = source;
    if(this.isConnected)
      this.#fillValues();
  }

  get source():TBrewSource|null { return this.#source; }

  #fillValues() {
    this.#eleMap.get("name").value = this.#source?.name || "";
    this.#eleMap.get("roast").value = this.#source?.roast || "";
    this.#eleMap.get("grind").value = this.#source?.grind || "";
  }
}
globalThis.customElements.define('editable-coffee-source', EditableCoffeeSource);